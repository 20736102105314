import React from 'react';
import {Link} from 'react-router-dom';
import Loader from './Loader';
import AppHeader from './AppHeader';
import BottomMenu from './BottomMenu';
import SidebarPanel from './SidebarPanel';
import {currencyFormat} from './Common.js';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import $ from "jquery";

class Item extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            LOCATION: this.props.location.pathname,
            LOADING: true, 
            ITEM_NAME: '',
            ITEM: [],
            ITEM_IMAGES:[],
            item_id: props.match.params.id,
            CATEGORY_ID: 0,
            BRAND_ID: 0,
            CUSTOMER_DET: '',
            FAVOURITES: 0,
            customerid: localStorage.getItem('userData'),
            inCart: 'N',
            addButton: 'Y',
            cartCount: '0',
            CART_LIST: [],
            Notification: '',
            type: props.match.params.type,
        };
    }

    GetItemDetail(cartList) 
    {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'ITEM', item_id:this.state.item_id, cartList:cartList, customer: this.state.customerid})
        };

        fetch(global.config.bURL+"item.php", requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {

            let ITEM_NAME = result.ITEM_NAME;
            let CATEGORY_ID = result.CATEGORY_ID;
            let BRAND_ID = result.BRAND_ID;
            let ITEM = result.ITEM;
            let ITEM_IMAGES = result.ITEM_IMAGES;
            let FAVOURITES = result.FAVOURITES;
            let CART_LIST = result.CART_LIST;
            var CUSTOMER_DET = 'Retail~~**~~Hi User';
            if(result.CUSTOMER!=='' && result.CUSTOMER!==undefined && result.CUSTOMER!=='undefined')
                CUSTOMER_DET = result.CUSTOMER;
                
            localStorage.setItem('nkis_favourites', FAVOURITES);
                
            this.setState({ 
                ITEM_NAME:ITEM_NAME,
                CATEGORY_ID:CATEGORY_ID,
                BRAND_ID:BRAND_ID,
                ITEM:ITEM,
                ITEM_IMAGES: ITEM_IMAGES,
                FAVOURITES: FAVOURITES,
                CUSTOMER_DET: CUSTOMER_DET,
                CART_LIST:CART_LIST,
                LOADING: false, 
            });
        });
    }

    handleClickAddToCart = (id)=>{

        let cartTotalCount = 0;

        var addToObject = function (obj, key, value, index) {

            // Create a temp object and index variable
            var temp = {};
            var i = 0;
        
            // Loop through the original object
            for (var prop in obj) {
                if (obj.hasOwnProperty(prop)) {
        
                    // If the indexes match, add the new item
                    if (i === index && key && value) {
                        temp[key] = value;
                    }
        
                    // Add the current item in the loop to the temp obj
                    temp[prop] = obj[prop];
        
                    // Increase the count
                    i++;
        
                }
            }
        
            // If no index, add to the end
            if (!index && key && value) {
                temp[key] = value;
            }
        
            return temp;
        
        };
                
        let cart = localStorage.getItem('cartData');
        
        var that = this;
        if (cart === null) {
            var a = {[id]: 1};
            /*console.log('typeof testObject: ' + typeof a);
            for (var prop in a) {
                console.log('  ' + prop + ': ' + a[prop]);
            }*/
            
            localStorage.setItem('cartData',JSON.stringify(a));

            let inCart = 'Y';
            let addButton = 'N';
            let cartCount = 1;

            that.setState({ inCart:inCart, addButton:addButton, cartCount:cartCount});
            /*var retrievedObject = localStorage.getItem('cartData');
            console.log('typeof retrievedObject: ' + typeof retrievedObject);
            console.log('Value of retrievedObject: ' + JSON.parse(retrievedObject));*/
        }
        else
        {
            var cartData = JSON.parse(cart);
            Object.keys(cartData).forEach(function(key) {
                //console.log(key);
                //console.log(cartData[key]);
                let cart_id = key;
                if(cart_id===id)
                {
                    cartTotalCount += cartData[key];
                }
    
                if(cart_id===id)
                {
                    delete cartData[key];
                }
            });
            cartTotalCount += 1;

            cartData = addToObject(cartData, [id], 1);
            localStorage.setItem('cartData', JSON.stringify(cartData));
            //console.log(localStorage.getItem('cartData'));

            let inCart = 'Y';
            let addButton = 'N';
            that.setState({ inCart:inCart, addButton:addButton, cartCount:cartTotalCount});
        }
        
        cart = localStorage.getItem('cartData');
        let itemLIST = '';
        if (cart !== null) {
            let cartData = JSON.parse(cart);
            Object.keys(cartData).forEach(function(key) {
                let id = key;
                itemLIST += id+',';
            });
        }

        if(itemLIST!=='') itemLIST = itemLIST.substring(0, itemLIST.length-1);

        this.GetItemDetail(itemLIST);
    }

    handleClickIncreement = (id,cartCount)=>{

        let cartTotalCount = 0;
        cartCount = cartCount + 1;
        let cart = localStorage.getItem('cartData');
        var that = this;
        var cartData = JSON.parse(cart);
        Object.keys(cartData).forEach(function(key) {
            //console.log(key);
            //console.log(cartData[key]);
            let cart_id = key;
            if(cart_id===id)
            {
                cartTotalCount += cartData[key];
            }

            if(cart_id===id)
            {
                cartData[key] = cartCount;
            }
        });
        cartTotalCount += 1;

        localStorage.setItem('cartData', JSON.stringify(cartData));

        let inCart = 'Y';
        let addButton = 'N';
        that.setState({ inCart:inCart, addButton:addButton, cartCount:cartTotalCount});
    }

    handleClickDecreement = (id,cartCount)=>{
        
        cartCount = (cartCount!==0)?cartCount - 1:0;
        let cart = localStorage.getItem('cartData');
        var that = this;
        var cartData = JSON.parse(cart);
        Object.keys(cartData).forEach(function(key) {
            //console.log(key);
            //console.log(cartData[key]);
            let cart_id = key;
            if(cart_id===id)
            {
                if(cartCount===0)
                    delete cartData[key];
                else
                    cartData[key] = cartCount;
            }
        });

        localStorage.setItem('cartData', JSON.stringify(cartData));
        cart = localStorage.getItem('cartData');
        if(cart === null || cart ==='{}')
        {
            localStorage.removeItem("cartData");
        }
        //else
            //localStorage.setItem('cartData', JSON.stringify(cartData));

        let inCart = (cartCount===0)?'N':'Y';
        let addButton = (cartCount===0)?'Y':'N';
        that.setState({ inCart:inCart, addButton:addButton, cartCount:cartCount});
    }

    handleItemFavourite = (type) => {

        let logged = 'N';
        if(localStorage.getItem('userData')) 
            logged = 'Y';

        if(logged==='Y')
        {
            var that = this;
            let cart = localStorage.getItem('cartData');
            let itemLIST = '';
            if (cart !== null) {
                let cartData = JSON.parse(cart);
                //console.log(cartData)
                
                Object.keys(cartData).forEach(function(key) {
                    let id = key;
                    itemLIST += id+',';
                });
            }
            
            if(itemLIST!=='') itemLIST = itemLIST.substring(0, itemLIST.length-1);

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ mode: 'ITEM', type:type, item_id:this.state.item_id, cartList:itemLIST, customer: this.state.customerid})
            };

            fetch(global.config.bURL+"item.php", requestOptions)
                .then(function (response) {
                    return response.json();
                })
                .then((result)=> {

                let ITEM_NAME = result.ITEM_NAME;
                let CATEGORY_ID = result.CATEGORY_ID;
                let ITEM = result.ITEM;
                let ITEM_IMAGES = result.ITEM_IMAGES;
                let FAVOURITES = result.FAVOURITES;
                let CART_LIST = result.CART_LIST;
                var CUSTOMER_DET = 'Retail~~**~~Hi User';
                if(result.CUSTOMER!=='' && result.CUSTOMER!==undefined && result.CUSTOMER!=='undefined')
                    CUSTOMER_DET = result.CUSTOMER;
                
                let Notification = 'Item Successfully Removed From Favourites';
                if(type==='ADD')
                    Notification = 'Item Successfully Added As A Favourite';
                
                localStorage.setItem('nkis_favourites', FAVOURITES);
                
                this.setState({ 
                    ITEM_NAME:ITEM_NAME,
                    CATEGORY_ID:CATEGORY_ID,
                    ITEM:ITEM,
                    ITEM_IMAGES: ITEM_IMAGES,
                    FAVOURITES: FAVOURITES,
                    CUSTOMER_DET: CUSTOMER_DET,
                    CART_LIST:CART_LIST,
                    Notification: Notification
                });

                window.$('#ModalFavouriteUpdated').modal('show');
            });
        }
        else
            window.$('#ModalNotLoggedIn').modal('show');
    }

    componentDidMount() {
        let mount = $("div").hasClass("modal-backdrop");
        if(mount===true)
        {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
        }

        let inCart = 'N'; //this.state.inCart;
        let addButton = 'Y'; //this.state.addButton;
        let cartCount = 0; //this.state.cartCount;

        let currentITEM = this.state.item_id;
        //console.log(localStorage.getItem('cartData'));

        var that = this;
        let cart = localStorage.getItem('cartData');
        let itemLIST = '';
        if (cart !== null) {
            let cartData = JSON.parse(cart);
            //console.log(cartData)
            
            Object.keys(cartData).forEach(function(key) {
                //console.log(key);
                //console.log(cartData[key]);
                let id = key;
                if(id===currentITEM)
                {
                    inCart = 'Y';
                    addButton = 'N';
                    cartCount += cartData[key];
                }
                itemLIST += id+',';

                that.setState({ inCart:inCart, addButton:addButton, cartCount:cartCount});
            });
        }
        
        if(itemLIST!=='') itemLIST = itemLIST.substring(0, itemLIST.length-1);

        this.GetItemDetail(itemLIST);
    }

    render() {

        let AppHeaderClass = 'nkis-header appHeader no-shadow title-cut';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = `/category/${this.state.CATEGORY_ID}`;
        if(this.state.type==='B') AppHeaderLeftLink = `/brand/${this.state.BRAND_ID}`;
    	let AppHeaderTitle = this.state.ITEM_NAME;
    	let AppHeaderIMAGE = '';
    	let AppHeaderRight = 'showLINK';
    	let AppHeaderRightLink = '';

        let images = this.state.ITEM_IMAGES.map((post, i)=>{
            let OutOfStock = '';
            if(post.STOCK==='N')
                OutOfStock = ' outofstock';

            return (
                <div className="item p-0 exercise" key={post.ID}>
                    <div className={`slider-outer${OutOfStock}`}>
                        <img src={post.IMAGE} alt={post.TITLE} className="imaged w-100 mb-4" />
                    </div>
                </div>
            );
        });

        const options = {
            items: 1,
            nav: false,
            loop: false,
            dots: true,
        };
    
        let addButton = this.state.addButton;
        let cartCount = this.state.cartCount;
        
        let cartItemCount = 0;
        let cartItemTotal = 0;
        let cart = localStorage.getItem('cartData');
        let cartData = JSON.parse(cart);
        let that = this;
        if(cart !== null && cart!=='{}')
        {
            Object.keys(cartData).forEach(function(key) {
                let Rate = 0;
                let itemTOTAL = 0;
    
                let cart_id = key;
                let itemID = cart_id;
                let itemCOUNT = cartData[key];
    
                that.state.CART_LIST.map(item=>{
                    if(item.ID===itemID)
                    {
                        if(item.STOCK==='N')
                        {
                            delete cartData[key];
                        }
                        else
                            Rate = item.PRICE;
                    }
                })

                itemTOTAL = itemCOUNT * Rate;
    
                cartItemCount = cartItemCount + itemCOUNT;
                cartItemTotal = cartItemTotal + itemTOTAL;
            });    
        }

        if(cartItemTotal>0)
        {
            cartItemTotal = cartItemTotal.toFixed(2);
            cartItemTotal = currencyFormat(cartItemTotal);
        }

        return (
            <div>
                {this.state.LOADING && <Loader />}
	    	    {this.state.ITEM_NAME!=='' && <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderIMAGE={AppHeaderIMAGE} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />}
                <div id="appCapsule">
                    <div className="category-listing login-form mt-1">
                        {this.state.ITEM.length!==0 &&
                            <div  className="instock-label bg-blue">
                                {this.state.ITEM.STOCK!=='N' && 
                                    <div className="in">
                                        <div className="text text-upper font-16"> In stock </div>
                                    </div>
                                }
                                {this.state.ITEM.STOCK==='N' && 
                                    <div className="in">
                                        <div className="text text-upper font-16"> Out of stock </div>
                                    </div>
                                }
                                {this.state.ITEM.DISCOUNT>0 &&
                                    <div className="chip chip-info chip-offer-detail bg-purple ml-05 mb-05">
                                        <span className="chip-label font-14">{this.state.ITEM.DISCOUNT}%</span>
                                        <span className="font-10 text-upper">Off</span>
                                    </div>
                                }
                            </div>
                        } 
                        {images.length>0 &&
                            <div className="section mt-0 mb-0 pt-0 pr-0 pl-0">
                                <div className="bg-white full m-0">
                                    <div className="nkis-carousel dash-carousel carousel-slider owl-carousel owl-theme">
                                        <OwlCarousel className="owl-theme" {...options}>
                                            {images}
                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.ITEM.length!==0 &&
                            <div>
                                <div className="section mt-2">
                                    <div className="program-outer">
                                        <div className="workout-types">
                                            <div className="flex-disp mb-1">
                                                <p className="card-text text-grey3 font-18 ft-wt-500 mb-1 text-upper text-left w-90">{this.state.ITEM.NAME}</p>
                                                {this.state.ITEM.IS_FAVOURITE==='N' && <ion-icon class="liked-cat unlike-filled font-50" name="heart" onClick={()=>{this.handleItemFavourite('ADD')}}></ion-icon>}
                                                {this.state.ITEM.IS_FAVOURITE==='Y' && <ion-icon class="liked-cat like-filled font-50" name="heart" onClick={()=>{this.handleItemFavourite('REMOVE')}}></ion-icon>}
                                            </div>
                                            <div className="flex-disp">
                                                <div className="price-display text-left">
                                                    <p className="mb-0 final-price text-dblue font-22 ft-wt-500 text-upper pr-05 pb-05"> &#8377; {this.state.ITEM.PRICE} </p>
                                                    {this.state.ITEM.MRP>0 && this.state.ITEM.MRP>this.state.ITEM.PRICE && <p className="mb-0 text-dgrey font-16">MRP
                                                        <small className="orginal-price font-14 pb-05">&#8377; {this.state.ITEM.MRP}</small>
                                                    </p>}
                                                    {this.state.ITEM.PRICE_PREGST>0 && <p className="mb-0 text-dgrey font-16">PRE GST
                                                        <small className="text-red font-14">&#8377; {this.state.ITEM.PRICE_PREGST}</small>
                                                    </p>}
                                                </div>
                                                {this.state.ITEM.STOCK!=='N' && 
                                                    <div style={{ display: addButton==='N' ? "none" : "block" }} onClick={()=>{this.handleClickAddToCart(this.state.item_id)}} className="add-btn add-btn-b4 mt-0 text-upper"> Add to cart </div>
                                                }
                                                {this.state.ITEM.STOCK!=='N' && 
                                                    <div style={{ display: addButton==='N' ? "block" : "none" }} className="add-btn add-btn-inc mt-0">
                                                        <button onClick={()=>{this.handleClickDecreement(this.state.item_id,cartCount)}} className="qtyminus"> - </button>
                                                            <input type="text" className="qty" value={cartCount} readOnly />
                                                        <button onClick={()=>{this.handleClickIncreement(this.state.item_id,cartCount)}} className="qtyplus"> + </button>
                                                    </div>
                                                }
                                            </div>
                                            {this.state.ITEM.CONTENT && <div className="component-specification" dangerouslySetInnerHTML={{ __html: this.state.ITEM.CONTENT }} /> }
                                        </div>
                                    </div>
                                </div>
                                {cartItemCount>0 &&
                                    <div className="form-button-group2">
                                        <div className="col-12">
                                            <div className="section-title w-100  d-flex justify-content-between">
                                                <div className="font-12 pt-1 pb-1 pr-0 pl-0 medium text-left text-white ft-wt-300"> Total<br />
                                                    <span className="font-16 lh-24">₹ {cartItemTotal}</span>
                                                </div>
                                                <Link to="/cart">
                                                    <div className="text-white ft-wt-300 text-upper view-cart">View Cart
                                                        <ion-icon name="arrow-forward-outline"></ion-icon>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                }   
                            </div>
                        }
                    </div>
                </div>
                <div className="modal fade dialogbox" id="ModalNotLoggedIn" data-backdrop="static" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-icon text-danger">
                                <ion-icon name="close-circle"></ion-icon>
                            </div>
                            <div className="modal-body">
                                <p className="if-no-add">Please log in to continue</p> 
                            </div>
                            <div className="modal-footer">
                                <div className="btn-inline"> 
                                    <button type="button" className="btn" data-dismiss="modal">Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade dialogbox" id="ModalFavouriteUpdated" data-backdrop="static" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-icon text-success">
                                <ion-icon name="checkmark-circle"></ion-icon>
                            </div>
                            <div className="modal-body">
                                <p className="if-no-add">{this.state.Notification}</p> 
                            </div>
                            <div className="modal-footer">
                                <div className="btn-inline"> 
                                    <button type="button" className="btn" data-dismiss="modal">Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BottomMenu LOCATION={this.state.LOCATION} />
                {this.state.CUSTOMER_DET && <SidebarPanel CUSTOMER_DET={this.state.CUSTOMER_DET}/>}
            </div>
        );
    }
}
      
export default Item;