import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import Loader from './Loader';
import AppHeader from './AppHeader';
import BottomMenu from './BottomMenu';
import SidebarPanel from './SidebarPanel';
import {currencyFormat} from './Common.js';

import $ from "jquery";

class Checkout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            LOCATION: this.props.location.pathname,
            LOADING: true,
            CART_ITEMS: [],
            PAYMENT_MODE: [],
            CartCount: 0,
            cartEMPTY: 'Y',
            CUSTOMER_DET: '',
            FAVOURITES: 0,
            customerid: localStorage.getItem('userData'),
            address_id: localStorage.getItem('addressID'),
            paymode_id: localStorage.getItem('paymodeID'),
            address_label: '',
            address_landmark:'',
            address_detail: '',
            address_mobile: '',
            submitOrder: false
        };
    }

    GetCartItemList(itemLIST) 
    {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'CART_ITEM', itemLIST:itemLIST, customer: this.state.customerid, address_id: this.state.address_id, paymode_id: this.state.paymode_id})
        };

        fetch(global.config.bURL+"cart_items.php", requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {

            //console.log(result);

            let ITEMS = result.CART_LIST;
            let PAY_MODE = result.PAYMENT_MODE;
            let FAVOURITES = result.FAVOURITES;
            var CUSTOMER_DET = 'Retail~~**~~Hi User';

            if(result.CUSTOMER!=='' && result.CUSTOMER!==undefined && result.CUSTOMER!=='undefined')
                CUSTOMER_DET = result.CUSTOMER;

            let address_id = 0;
            if(result.ADDRESS_ID!=='' && result.ADDRESS_ID!==undefined && result.ADDRESS_ID!=='undefined')
            {
                address_id = result.ADDRESS_ID;
                localStorage.setItem('addressID',address_id);
            }

            let address_label = '';
            if(result.ADDRESS_LABEL!=='' && result.ADDRESS_LABEL!==undefined && result.ADDRESS_LABEL!=='undefined')
                address_label = result.ADDRESS_LABEL;

            let address_landmark = '';
            if(result.ADDRESS_LANDMARK!=='' && result.ADDRESS_LANDMARK!==undefined && result.ADDRESS_LANDMARK!=='undefined')
                address_landmark = result.ADDRESS_LANDMARK;

            let address_detail = '';
            if(result.ADDRESS_DETAIL!=='' && result.ADDRESS_DETAIL!==undefined && result.ADDRESS_DETAIL!=='undefined')
                address_detail = result.ADDRESS_DETAIL;

            let address_mobile = '';
            if(result.ADDRESS_MOBILE!=='' && result.ADDRESS_MOBILE!==undefined && result.ADDRESS_MOBILE!=='undefined')
                address_mobile = result.ADDRESS_MOBILE;

            this.setState({ 
                CART_ITEMS:ITEMS,
                PAYMENT_MODE: PAY_MODE,
                cartEMPTY:'N', 
                FAVOURITES: FAVOURITES,
                CUSTOMER_DET: CUSTOMER_DET,
                LOADING: false,
                address_id: address_id,
                address_label: address_label,
                address_landmark: address_landmark,
                address_detail: address_detail,
                address_mobile: address_mobile,
            });
        });
    }

    handleCheckOutSubmit = () => {

        let refNO = $('#txtrefno').val();
        if(refNO!=='')
        {
            localStorage.setItem('refNO', refNO);
            this.setState({ submitOrder:true});
        }
        else
            window.$('#ModalReferenceNoMissing').modal('show');
    }

    componentDidMount() {
        let mount = $("div").hasClass("modal-backdrop");
        if(mount===true)
        {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
        }

        var that = this;
        let cart = localStorage.getItem('cartData');

        let itemLIST = '';
        if (cart !== null) {
            let cartData = JSON.parse(cart);
            //console.log(cartData)
            
            Object.keys(cartData).forEach(function(key) {
                //console.log(key);
                //console.log(cartData[key]);
                let id = key;
                itemLIST += id+',';
            });
        }

        if(itemLIST!=='')
        {
            itemLIST = itemLIST.substring(0, itemLIST.length-1);
            this.GetCartItemList(itemLIST);
        }
        else
        {
            this.GetCustomerDetails();
        }
    }

    render() { 

        let AppHeaderClass = 'nkis-header appHeader no-shadow';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = '/cart';
    	let AppHeaderTitle = 'Order Summary';
    	let AppHeaderIMAGE = '';
    	let AppHeaderRight = 'showLINK';
    	let AppHeaderRightLink = '';

        let cartTOTAL = 0;
        let taxTOTAL = 0;
        let savingsTOTAL = 0;

        //localStorage.removeItem('userData');

        let logged = 'N';
        if(localStorage.getItem('userData')) 
            logged = 'Y';

        let address_id = '';
        if(localStorage.getItem('addressID'))
            address_id = localStorage.getItem('addressID');

        let cart = localStorage.getItem('cartData');
        let cartData = JSON.parse(cart);
    
        if(cart !== null && cart!=='{}')
        {
            let LIST = this.state.CART_ITEMS;
            Object.keys(cartData).forEach(function(key) {
                let cart_id = key.split('~~**~~');
    
                if(LIST.length>0)
                {
                    LIST.map(id =>{
    
                        if(cart_id[0]==id.ITEMID)
                        {
                            if(id.STOCK==='N')
                            {
                                delete cartData[key];
                            }
                        }
                    });
                }
            });
    
            localStorage.setItem('cartData', JSON.stringify(cartData));
        }
    
        cart = localStorage.getItem('cartData');
        cartData = JSON.parse(cart);
        let cartItemCount = 0;
        let cartEMPTY = 'Y';
    
        if(cart !== null && cart!=='{}')
        {
            cartEMPTY = 'N';
            Object.keys(cartData).forEach(function(key) {
    
                let itemCOUNT = cartData[key];
                cartItemCount = cartItemCount + itemCOUNT;
            });
        }
            
        if (cartEMPTY=='Y') {
            return (<Redirect to={'/home'}/>)
        }

        let delTYPE = '';
        if(localStorage.getItem('deliveryTYPE'))
            delTYPE = localStorage.getItem('deliveryTYPE');

        if (delTYPE==='') {
            return (<Redirect to={'/cart'}/>)
        }
         
        let DeliveryType = '';
        if(delTYPE==='D') DeliveryType = 'Delivery';
        else if(delTYPE==='P') DeliveryType = 'Pick Up';

        let paymode_id = '';
        if(localStorage.getItem('paymodeID'))
            paymode_id = localStorage.getItem('paymodeID');

        if (paymode_id==='') {
            return (<Redirect to={'/cart'}/>)
        }

        let PayMode = '';
        if(this.state.PAYMENT_MODE.length>0)
        {
            this.state.PAYMENT_MODE.map(post => {
                if(paymode_id!=='')
                {
                    if(paymode_id===post.ID)
                        PayMode = post.TITLE;
                }
            })
        }

        if(this.state.submitOrder)
        {
            return (<Redirect to={'/submit_order'}/>)
        }

        return (
            <div>
                {this.state.LOADING && <Loader />}
	    	    <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderIMAGE={AppHeaderIMAGE} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
                <div id="appCapsule">
                    <div className="section full mt-1">
                        <div className="cart-list-items">
                            <ul className="listview image-listview">
                                <li className="cart-list-head noafter">
                                    <div className="item">
                                        <div className="font-15 mb-0 title-cut-sub text-left text-grey4 ft-wt-400 pr-1 pl-0 pt-0 pb-0 text-upper text-grey4">Product </div>
                                        <div className="in">
                                            <div className="item-name font-12 pr-1">
                                                <div className="add-btn add-btn-cart tp-neg-10 add-btn-inc mt-0 text-center font-15 text-grey4 text-upper ft-wt-400"> Qty </div>
                                            </div>
                                            <span className="badge bg-transparent badge-light mt-0 font-15 text-grey4 text-upper ft-wt-400">Total</span>
                                        </div>
                                    </div>
                                </li>
                                {
                                    this.state.CART_ITEMS.map(item=>{
                                    let cartCount = 0; //this.state.cartCount;

                                    let currentITEM = item.ID;
                                    let cart = localStorage.getItem('cartData');
                                    if (cart !== null) {
                                        let cartData = JSON.parse(cart);
                                        Object.keys(cartData).forEach(function(key) {
                                            let id = key;
                                            if(id===currentITEM)  
                                                cartCount += cartData[key];
                                            });
                                        }

                                        let itemTOTAL = parseFloat(item.PRICE) * parseFloat(cartCount);
                                        cartTOTAL = parseFloat(cartTOTAL) + parseFloat(itemTOTAL);
                                                    
                                        if(itemTOTAL>0)
                                            itemTOTAL = currencyFormat(itemTOTAL);

                                        let itemTAXTOTAL = parseFloat(item.TAX) * parseFloat(cartCount);
                                        taxTOTAL = parseFloat(taxTOTAL) + parseFloat(itemTAXTOTAL);

                                        let itemSAVINGS = parseFloat(item.SAVINGS) * parseFloat(cartCount);
                                        savingsTOTAL = parseFloat(savingsTOTAL) + parseFloat(itemSAVINGS);

                                        if(cartCount>0)
                                        {
                                            return (
                                                <li key={item.ID}>
                                                    <div className="item"> 
                                                        <div className="title-cut-sub text-left text-grey4 font-13 ft-wt-500 pb-05"> {item.NAME}</div>
                                                        <div className="in">
                                                            <div className="item-name font-12 pr-1">
                                                                <div className="add-btn add-btn-cart tp-neg-10 add-btn-inc mt-0 text-center font-15 ft-wt-400"> &#8377; {item.PRICE} X {cartCount} </div>
                                                            </div>
                                                            <span className="badge badge-light mt-2 font-15 ft-wt-400">&#8377; {itemTOTAL}</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            );
                                        }
                                    })
                                }
                                { taxTOTAL>0 &&
                                    <li className="cart-list-head">
                                        <div className="section-title w-100 font-13 text-upper d-flex justify-content-between pt-2 pb-2 pr-2 pl-2 text-grey4"> Inclusive Taxes Breakups
                                            <span className="ft-wt-500">&#8377; {currencyFormat(taxTOTAL.toFixed(2))}</span>
                                        </div>
                                    </li>
                                }
                                { savingsTOTAL>0 &&
                                    <li className="cart-list-head">
                                        <div className="section-title w-100 font-13 text-upper d-flex justify-content-between pt-2 pb-2 pr-2 pl-2 text-grey4"> Total Savings 
                                            <span className="ft-wt-500">&#8377; {currencyFormat(savingsTOTAL.toFixed(2))}</span>
                                        </div>
                                    </li>
                                }
                                <li className="ht-70 noafter">
                                    <div className="section-title w-100 text-upper d-flex justify-content-between pt-2 pb-2 pr-2 pl-2 ">Grand Total 
                                        <span className="font-weg-600">&#8377; {currencyFormat(cartTOTAL.toFixed(2))}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex-disp1">
                        <div className="section-title disp-block w-50 font-14 mb-05 ft-wt-500 text-upper mt-1 pr-2 pl-2 justify">Delivery Type:<br/><span className="flex-disp1 tick font-13"><ion-icon name="checkmark"></ion-icon> {DeliveryType} </span></div>
                        {PayMode!=='' && <div className="section-title disp-block w-50 font-14 mb-05 ft-wt-500 text-upper mt-1 pr-2 pl-2">Payment Mode:<br/><span className="flex-disp1 tick font-13"> <ion-icon name="checkmark"></ion-icon> {PayMode}</span> </div>}
                    </div>
                    {paymode_id==='2' && 
                        <div className="section-title justify font-14 mb-05 ft-wt-500 text-upper mt-1 pr-2 pl-2">Reference No:
                            <input type="text" className="form-control font-14" name="txtrefno" id="txtrefno" placeholder="Reference Num" required autoComplete="off"/>
                        </div>
                    }

                    <div className="section-title font-14 mb-05 ft-wt-500 text-upper mt-1 pr-2 pl-2">Delivery Location </div>
                    <div className="section full mt-1 pr-2 pl-2">
                        <p className="text-left font-13 mb-0 pb-1">{this.state.address_detail}</p>
                        <p className="text-left font-13 mb-0">{this.state.address_mobile}</p>
                        <div className="form-group boxed">
                            {paymode_id==='1' && 
                                <Link to="/submit_order" className="btn btn-dblue btn-block btn-lg font-16 text-upper ft-wt-300 w-100 br-4 mb-05">Pay &#8377; 47,800 Now  &nbsp;
                                    <ion-icon className="font-20" name="arrow-forward-outline"></ion-icon>
                                </Link>
                            }
                            {paymode_id!=='1' && 
                                <Link to="#" onClick={()=>{this.handleCheckOutSubmit()}} className="btn btn-dblue btn-block btn-lg font-16 text-upper ft-wt-300 w-100 br-4 mb-05">Submit Order  &nbsp;
                                    <ion-icon className="font-20" name="arrow-forward-outline"></ion-icon>
                                </Link>
                            }
                        </div>
                    </div>
                </div>
                <div className="modal fade dialogbox" id="ModalReferenceNoMissing" data-backdrop="static" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-icon text-danger">
                                <ion-icon name="close-circle"></ion-icon>
                            </div>
                            <div className="modal-body">
                                <p className="if-no-add">Please enter reference number</p> 
                            </div>
                            <div className="modal-footer">
                                <div className="btn-inline"> 
                                    <button type="button" className="btn" data-dismiss="modal">Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BottomMenu LOCATION={this.state.LOCATION} />
                {this.state.CUSTOMER_DET && <SidebarPanel CUSTOMER_DET={this.state.CUSTOMER_DET}/>}
            </div>
        );
    }
}
      
export default Checkout;