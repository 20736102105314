import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import Loader from './Loader';
import AppHeader from './AppHeader';
import SidebarPanel from './SidebarPanel';

import $ from "jquery";

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            LOADING: false, 
            Notifications: false,
            Password: '',
            Password2: '',
            Updated: false,
            CUSTOMER_DET: ''
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let err = 0;
        if ($('#frmPassword')[0].checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            err++;
        }
        $('#frmPassword')[0].classList.add('was-validated');

        const password = $('#txtpassword').val().trim();
        const password2 = $('#txtpassword2').val().trim();

        if(password!==password2)
        {
            err++;
            $('#txtpassword2').val('');
        }

        if(err===0)
        {
			var curr_pass = $('#txtcurrentpassword').val();
			$('#txtcurrentpassword').val(window.b64_md5(curr_pass));

            var pass = $('#txtpassword').val();
			$('#txtpassword').val(window.b64_md5(pass));

			var pass2 = $('#txtpassword2').val();
			$('#txtpassword2').val(window.b64_md5(pass2));

			$('#submitLoading').css('display','block');
            
            const method = "POST";
            const body = new FormData(this.form);
            //console.log(body);
            var that = this;
            fetch(global.config.bURL+'update_password.php', { method, body })
            .then(function (response) {
                //console.log(response);
                return response.json();
            })
            .then(function (result) {


                //console.log(result);
                //alert(result);
                let updated = false;
                let notify = '';
                if(result.err!=='' && result.err!==undefined)
                    notify = result.err;
                else
                    updated = true;

                that.setState({ Notifications: notify, Updated:updated, Password:'', Password2:''});
                $('#submitLoading').css('display','none');
                if(notify!=='')
                    window.$('#ModalAlert').modal('show');
                if(updated)
                    window.$('#ModalUpdated').modal('show');
          });
        }
    }

    componentDidMount() {
        let mount = $("div").hasClass("modal-backdrop");
        if(mount===true)
        {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
            //window.location.reload(true);
        }

    }
    
    render() { 

        if(localStorage.getItem('userData')==null)
        {
	        return (<Redirect to={'/home'}/>)
        }

        let AppHeaderClass = 'nkis-header appHeader no-shadow';
        let AppHeaderLeft = 'showLINK';
        let AppHeaderLeftLink = '/profile';
        let AppHeaderTitle = 'Change Password';
        let AppHeaderIMAGE = '';
        let AppHeaderRight = 'showLINK';
        let AppHeaderRightLink = '';

        return (
            <div>
                {this.state.LOADING && <Loader />}
                <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderIMAGE={AppHeaderIMAGE} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
                <div id="appCapsule">
                    <div className="login-form mt-1 pl-2 pr-2">
                        <div className="section mt-4 mb-5 pt-3">
                            <form id="frmPassword" encType="multipart/form-data" ref={el => (this.form = el)} onSubmit={this.handleSubmit} className="needs-validation" noValidate>
                                <input type="hidden" value="PASSWORD" className="form-control" name="mode" id="mode"  />
                                <input type="hidden" value={localStorage.getItem('userData')} className="form-control" name="customer" id="customer" />
                                <div className="form-group boxed mb-1">
                                    <div className="input-wrapper">
                                        <input type="password" className="form-control font-14" name="txtcurrentpassword" id="txtcurrentpassword" required placeholder="Current Password" autoComplete="off"/> {/*pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" */}
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div> 
                                        <div className="invalid-feedback">Please enter your current password.</div>
                                    </div>
                                </div>
                                <div className="form-group boxed mb-1">
                                    <div className="input-wrapper">
                                        <input type="password" className="form-control font-14" name="txtpassword" id="txtpassword" minLength="8" required placeholder="New Password" autoComplete="off"/> {/*pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" */}
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div> 
                                        <div className="invalid-feedback">Must contain at least 8 or more characters.</div>
                                    </div>
                                </div>
                                <div className="form-group boxed mb-1">
                                    <div className="input-wrapper">
                                        <input type="password" className="form-control font-14" name="txtpassword2" id="txtpassword2" minLength="8" required placeholder="Re-enter New Password"  autoComplete="off"/> {/* pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"*/}
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div> 
                                        <div className="invalid-feedback">Must contain at least 8 or more characters.</div>
                                    </div>
                                </div>
                                <div className="form-group boxed">
                                    <button type="submit" className="btn btn-red btn-block btn-lg font-14 text-upper ft-wt-400 w-100 br-4 mb-05">
                                        <span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{display: 'none'}} role="status" aria-hidden="true"></span>
                                        Save</button>
                                </div>
                            </form>
                        </div>
                        <div className="modal fade dialogbox" id="ModalAlert" data-backdrop="static" tabIndex="-1" role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-icon text-danger">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </div>
                                    <div className="modal-body"> 
                                            {this.state.Notifications} 
                                    </div>
                                    <div className="modal-footer">
                                        <div className="btn-inline"> 
                                            <button type="button" className="btn" data-dismiss="modal">Ok</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade dialogbox" id="ModalUpdated" data-backdrop="static" tabIndex="-1" role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-icon text-success">
                                        <ion-icon name="checkmark-circle"></ion-icon>
                                    </div>
                                    <div className="modal-body"> 
                                        Password Details Updated Successfully 
                                    </div>
                                    <div className="modal-footer">
                                        <div className="btn-inline"> 
                                            <button type="button" className="btn" data-dismiss="modal">Ok</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
      
export default ChangePassword;