import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import Loader from './Loader';
import GoogleLogin from 'react-google-login';
import AppHeader from './AppHeader';

import $ from "jquery";

const responseGoogle = (response) => {
    console.log(response);
}
  
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            LOADING: true, 
            Notifications: false,
            Logged: false,
            CUSTOMER_DET: ''
        };

        this.loginGoogleUser = this.loginGoogleUser.bind(this);
    }

	loginGoogleUser = (response) => {

		let profileObj = response.profileObj;
		var userObject = {
							mode:"GOOGLE",
							googleId: response.googleId,
							accessToken:response.accessToken,
							email:profileObj.email,
							lastName:profileObj.familyName,
							firstName:profileObj.givenName,
							profileImage: profileObj.imageUrl,
							fullName: profileObj.name,
                            webpushr_id:localStorage.getItem('WEBPUSHR_ID')
						};

		localStorage.setItem('userObject', JSON.stringify(userObject));
		var userData = localStorage.getItem('userObject');
		var userDataJson = JSON.parse(userData);

		const requestOptions = {
        	method: 'POST',
        	headers: { 'Content-Type': 'application/json' },
        	body: JSON.stringify(userObject)
    	};

        var that = this;
		// fetch check if google id/email id exists
		fetch(global.config.bURL+'login_api.php', requestOptions)
			.then(function(response) {
				return response.json();
			})
			.then(function(result) {
                let notify = '';
                if(result.err!=='' && result.err!==undefined)
                    notify = result.err;

                let logged = false;

                let customer = result.customer;
                if(customer!=='' && customer!==undefined)
                {
                    localStorage.setItem('userData',customer);
                    logged = true;
                }

                that.setState({ Notifications: notify, Logged:logged });
                if(notify!=='')
                    window.$('#ModalAlert').modal('show');
			});
	}

    handleSubmit = (e) => {
        e.preventDefault();

        let err = 0;
        if ($('#frmLogin')[0].checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            err++;
        }
        $('#frmLogin')[0].classList.add('was-validated');

        if(err===0)
        {
			var pass = $('#txtpassword').val();
			$('#txtpassword').val(window.b64_md5(pass));
			
            $('#submitLoading').css('display','block');
            
            const method = "POST";
            const body = new FormData(this.form);
            //console.log(body);
            var that = this;
            fetch(global.config.bURL+'login_api.php', { method, body })
            .then(function (response) {
                //console.log(response);
                return response.json();
            })
            .then(function (result) {

                let notify = '';
                if(result.err!=='' && result.err!==undefined)
                    notify = result.err;

                let logged = false;

                let customer = result.customer;
                if(customer!=='' && customer!==undefined)
                {
                    localStorage.setItem('userData',customer);
                    logged = true;
                }

                that.setState({ Notifications: notify, Logged:logged });
                $('#submitLoading').css('display','none');
                if(notify!=='')
                    window.$('#ModalAlert').modal('show');
          });
        }
    }

    componentDidMount() {
        let mount = $("div").hasClass("modal-backdrop");
        if(mount===true)
        {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
        }

        this.setState({
    		LOADING: false,
    	});
    }

    render() {

        if(localStorage.getItem('userData')) {
            if(localStorage.getItem('cartData'))
                return (<Redirect to={'/cart'}/>)
            else
			    return (<Redirect to={'/home'}/>)
		}

        if (this.state.Logged) {
            if(localStorage.getItem('cartData'))
                return (<Redirect to={'/cart'}/>)
            else
			    return (<Redirect to={'/home'}/>)
		}

        let cartEMPTY = 'Y';
        let cart = localStorage.getItem('cartData');
        let cartData = JSON.parse(cart);
        if(cart !== null && cart!=='{}')
        {
            cartEMPTY = 'N';
        }
        
        let AppHeaderClass = 'nkis-header appHeader no-shadow';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = '/home';
    	let AppHeaderTitle = 'Login';
    	let AppHeaderIMAGE = '';
    	let AppHeaderRight = '';
    	let AppHeaderRightLink = '';
    
        return (
            <div>
                {this.state.LOADING && <Loader />}
	    	    <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderIMAGE={AppHeaderIMAGE} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
                <div id="appCapsule">
                    <div className="login-form mt-1 pl-2 pr-2">
                        <div className="section mt-6 mb-5 pt-3">
                            {/*<Link to="#">
                                <div className="with-google btn btn-outline-danger bw-2 bg-white btn-lg btn-block bt-rel font-14 text-upper text-grey mb-1 bt-ht-50">
                                    <div className="outer-circle bg-google">
                                        <i className="fa fa-google-plus" aria-hidden="true"></i>
                                    </div>
                                    Login using Google
                                </div>
                            </Link>*/}
                            <div className="with-google2">
                                <GoogleLogin 
                                    clientId="202481004765-nifnb1i75832bb6it98e7cptr1sehtsr.apps.googleusercontent.com"
                                    buttonText="LOGIN USING GOOGLE"
                                    cookiePolicy={'single_host_origin'}
                                    onSuccess={this.loginGoogleUser}
                                    onFailure={responseGoogle}
                                    className="outer-circle mar-auto bg-google google-nkis"
                                    style={{width:'100%'}}
                                />
                            </div>
                            <br /><br />
                            <div className="mt-2 mb-2">
                                <p>OR</p>
                            </div>
                            <form name="frmLogin" id="frmLogin" ref={el => (this.form = el)} onSubmit={this.handleSubmit} className="needs-validation" noValidate>
                                <input type="hidden" defaultValue="SIGNIN" className="form-control" name="mode" id="mode" required />
                                <input type="hidden" className="form-control" defaultValue={localStorage.getItem('WEBPUSHR_ID')} name="webpushr_id" id="webpushr_id" />
                                <div className="form-group boxed mb-1">
                                    <div className="input-wrapper">
                                        <input type="text" className="form-control font-14" name="txtemail" id="txtemail" placeholder="Email Id / Mobile No" required autoComplete="off"/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div>
                                        <div className="invalid-feedback">Please enter your registered email id or mobile no.</div>
                                        
                                    </div>
                                </div>
                                <div className="form-group boxed mb-1">
                                    <div className="input-wrapper">
                                        <input type="password" className="form-control font-14" name="txtpassword" id="txtpassword" placeholder="Password" required autoComplete="off"/>
                                        <button className="show-password">
                                            <ion-icon name="eye-outline"></ion-icon>
                                        </button>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div> 
                                        <div className="invalid-feedback">Please enter your password.</div>  
                                    </div>
                                </div>
                                <div className="form-group boxed">
                                    <button type="submit" className="btn btn-primary btn-block btn-lg font-14 text-upper ft-wt-400 w-50 br-4 mb-05">
                                        <span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{display: 'none'}} role="status" aria-hidden="true"></span>
                                        Sign in</button>
                                </div>
                                <div className="form-links mt-1">
                                    <div className="w-100">
                                        <Link to="/forgot-password" className="text-muted font-12">Forgot Password?</Link>
                                    </div>
                                </div>
                                <div className="fixed-ft form-button-group bg-peach">
                                    <div className="w-50 text-left">
                                        <Link className="ml-1 ft-wt-500 font-13 text-grey1 text-upper ft-wt-400" to="/register">New User</Link>
                                    </div>
                                    <div className="w-50 text-right">
                                        <Link className="mr-1 ft-wt-500 font-13 text-upper text-red" to="/register">Click Here To Register</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal fade dialogbox" id="ModalAlert" data-backdrop="static" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-icon text-danger">
                                    <ion-icon name="close-circle"></ion-icon>
                                </div>
                                <div className="modal-body"> 
                                    {this.state.Notifications} 
                                </div>
                                <div className="modal-footer">
                                    <div className="btn-inline"> 
                                        <button type="button" className="btn" data-dismiss="modal">Ok</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
      
export default Login;