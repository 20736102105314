import React from 'react';
import {Link} from 'react-router-dom';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


const HomeNewItems = (props) => {

    let Items = props.items.map((post, i)=>{
        return (
            <Link to={`/item/${post.ID}`} key={post.ID}>
                <div className="item">
                    <div className="card mb-0">
                        <div className="cat-img-cover">
                            <div className="cat-img">
                                <img src={post.IMAGE} className="card-img-top imaged w-100" alt={post.ALT_TAG} />
                            </div>
                            {post.DISCOUNT>0 && 
                                <div className="chip chip-info chip-offer bg-purple  ml-05 mb-05">
                                    <span className="chip-label font-16">{post.DISCOUNT}%</span>
                                    <span className="font-11 text-upper">Off</span>
                                </div>
                            }
                        </div>
                        <div className="card-body pt-10 pb-05 pr-05 pl-05">
                            <p className="card-text text-grey4 font-14 ft-wt-400 mb-0 text-upper text-left">{post.NAME} </p>
                            <div className="cat-price pt-05">
                                <p className="mb-0 final-price text-dblue font-15 ft-wt-400 text-upper pr-05"> &#8377; {post.PRICE} </p>
                                {post.MRP>0 && post.MRP>post.PRICE && <p className="mb-0 text-dgrey orginal-price"><small>&#8377; {post.MRP}</small></p>}
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        );
    });

    const options = {
        items: 2,
        nav: false,
        loop: false,
        dots: false,
        stagePadding: 20,
        margin: 10,
    };

    return (
        <div className="dashboard-area item-list-slides section full mb-3 mt-4">
            <div className="section-title font-16 mb-05 text-grey2 ft-wt-400 text-upper">New Products 
                <Link to="/items/N">
                    <span className="badge bt-blue font-size-10 ht-20">View All</span>
                </Link>
            </div>
            <div className="carousel-multiple owl-carousel owl-theme">
                <OwlCarousel className="owl-theme" {...options}>
                    {Items}
                </OwlCarousel>
            </div>
        </div>
    );
}
  
export default HomeNewItems;