import React from 'react';
import {Link} from 'react-router-dom';
  
class SidebarPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            CUSTOMER_DET: props.CUSTOMER_DET
        };
    }

    render() { 

        let cartItemCount = 0;
        let cart = localStorage.getItem('cartData');
        let cartData = JSON.parse(cart);
        if(cart !== null && cart!=='{}')
        {
            Object.keys(cartData).forEach(function(key) {
                let itemCOUNT = cartData[key];
                cartItemCount = parseFloat(cartItemCount) + parseFloat(itemCOUNT);
            });    
        }

        let MEM_TYPE = '';
        let NAME = '';
        let EMAIL = '';
        let MOBILE = '';

        if(this.state.CUSTOMER_DET!=='')
        {
            let customer = this.state.CUSTOMER_DET.split('~~**~~');
            MEM_TYPE = customer[0];
            NAME = customer[1];
            if(customer[2]!=='' && customer[2]!==undefined)
                EMAIL = customer[2];
            if(customer[3]!=='' && customer[3]!==undefined)
                MOBILE = customer[3];
        }

        let logged = 'N';
        if(localStorage.getItem('userData')) 
            logged = 'Y';

        return (
            <div className="modal fade panelbox panelbox-left" id="sidebarPanel" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            <div className="profileBox bg-blue">
                                <div className="in pt-3 pb-3"> <strong className="pb-05">{NAME}</strong>
                                    {MOBILE!== '' && <div className="text-muted">
                                        <ion-icon name="call-outline"></ion-icon>{MOBILE}
                                        </div>
                                    }
                                </div>
                                <Link to="javascript:;" className="close-sidebar-button" data-dismiss="modal">
                                    <ion-icon name="close"></ion-icon>
                                </Link>
                            </div>
                            <ul className="listview flush transparent no-line image-listview mt-2">
                                <li>
                                    <Link to="/home" className="item">
                                        <div className="in"> Home </div>
                                    </Link>
                                </li>
                                {logged==='Y' && 
                                    <li>
                                        <Link to="/orders" className="item">
                                            <div className="in"> Orders </div>
                                        </Link>
                                    </li>
                                }
                                <li>
                                    <Link to="/categories" className="item">
                                        <div className="in"> Categories </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/brands" className="item">
                                        <div className="in"> Brands </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/search" className="item">
                                        <div className="in">
                                            <div>Search</div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/cart" className="item">
                                        <div className="in">
                                            <div>Cart</div>
                                            {cartItemCount!==0 && <span className="badge badge-danger">{cartItemCount}</span>}
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
      
export default SidebarPanel;