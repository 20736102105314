import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import Loader from './Loader';
import AppHeader from './AppHeader';
import BottomMenu from './BottomMenu';

import $ from "jquery";

const CustomerAddressesList = (props) => {
    return (
        props.address.map((post)=>{
            return (
                <div className="wide-block pt-2 pb-2 text-left" key={post.ID}>
                    <div className="sumup pr-1 pl-1">
                        <h5 className="font-weg-400 mb-05">&nbsp;
                            <span className="badge chip-warning chip-outline ht-17 font-10 ft-wt-500 text-upper ltsp">{post.LABEL}</span>
                        </h5>
                        <p className="font-13 ft-wt-300 mb-05">{post.ADDRESS}</p>
                        {post.MOBILE_NO && <div className="stateline pin">
                            <span className="text-lgrey font-12 mb-05 mr-1">{post.MOBILE_NO}</span>
                        </div>}
                        <div className="stateline pt-1">
                            <Link to={`/addressdetails/${post.ID}`} type="button" className="btn btn-xsm square br-4 btn-outline-blue newshadowed shadowed mr-1 mb-1 text-upper">Edit</Link>
                            <button onClick={()=>{props.handleDeleteClickModal(post.ID)}} type="button" className="btn btn-xsm square br-4 btn-outline-red newshadowed shadowed mr-1 mb-1 text-upper">Delete</button>
                        </div>
                    </div>
                    <div className="modal fade dialogbox" id={`ModalDeleteAddress_${post.ID}`} data-backdrop="static" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">&nbsp;</h5>
                                </div>
                                <div className="modal-body"> 
                                    You are about to delete this address. Continue? 
                                </div>
                                <div className="modal-footer">
                                    <div className="btn-inline"> 
                                        <button type="button" className="btn btn-text-success" data-dismiss="modal" onClick={()=>{props.handleClickDeleteAddress(post.ID)}}>Yes</button>
                                        <button type="button" className="btn btn-text-danger" data-dismiss="modal">No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    );
}

class CustomerAddresses extends React.Component {
    constructor(props) {
        //console.log(props);
        super(props);
        this.state = {
            LOADING: true,
            LOCATION: this.props.location.pathname,
            CustomerAddresses: [],
            customerid: localStorage.getItem('userData'),
            CUSTOMER_DET: ''
        };
    }

    GetCustomerAddressList() {
        var that = this;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'ADDRESS_LIST', customer: this.state.customerid, address_id: this.state.address_id})
        };

        fetch(global.config.bURL+"address_list.php", requestOptions)
        .then(function (response) {
            return response.json();
        }).then(function (result) {
            //console.log(result);
            
            let customer_addresses = result.ADDRESS_LIST;
            if(customer_addresses==='' || customer_addresses===undefined)
                customer_addresses = '';

            that.setState({ LOADING:false, CustomerAddresses:customer_addresses });
        });
    }

    handleDeleteClickModal = (id) => {
        window.$('#ModalDeleteAddress_'+id).modal('show');
    }
        
    handleClickDeleteAddress = (id) => {
        $('#submitLoading_'+id).css('display','block');
            
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'DELETE_ADDRESS', address_id:id, customer: this.state.customerid})
        };

        fetch(global.config.bURL+"address_api2.php", requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {

            //console.log(result);
            let deleted = result.deleted;
            if(deleted==='' || deleted===undefined)
                deleted = '';

            if(deleted==='Y')
            {
                let address_id = localStorage.getItem('addressID');
                if(address_id===id)
                {
                    localStorage.removeItem('addressID');
                }
            }

            this.GetCustomerAddressList();
            if(deleted==='Y')
                window.$('#ModalAlert2').modal('show');
        });
    }

    componentDidMount() {
        let mount = $("div").hasClass("modal-backdrop");
        if(mount===true)
        {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
            //window.location.reload(true);
        }

        this.GetCustomerAddressList();
    }

    render() { 

        let logged = 'N';
        if(localStorage.getItem('userData'))
            logged = 'Y';

        if (logged==='N')
        {
            if(localStorage.getItem('cartData'))
                return (<Redirect to={'/cart'}/>)
            else
                return (<Redirect to={'/home'}/>)
        }
        
        let AppHeaderClass = 'nkis-header appHeader no-shadow';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = '/home';
    	let AppHeaderTitle = 'Delivery Locations';
    	let AppHeaderIMAGE = '';
    	let AppHeaderRight = 'showLINK';
    	let AppHeaderRightLink = '';

        let props = {
            address: this.state.CustomerAddresses,
            handleClickDeleteAddress: this.handleClickDeleteAddress,
            handleDeleteClickModal: this.handleDeleteClickModal
        };

        return (
            <div>
                {this.state.LOADING && <Loader />}
	    	    <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderIMAGE={AppHeaderIMAGE} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
                <div id="appCapsule">
                    <div className="login-form mt-1">
                        <div className="section-title font-size-17 mb-0 text-sdgrey pr-2 pl-2 pt-1 pb-1 text-upper text-grey4">Edit Address </div>
                        <div className="delivery-location section mt-0 mb-5 pt-0 pl-0 pr-0">
                            { this.state.CustomerAddresses.length!=='' && <CustomerAddressesList {...props} /> }
                            { this.state.CustomerAddresses.length==='' && 
                            <div className="section pt-6" style={{textAlign: 'center'}}>
                                <img src="/assets/img/no-address.png" alt="no-address" className="form-image imaged w200" />
                            </div>}

                            <div className="carousel-button-footer">
                                <div className="row">
                                    <div className="col-12">
                                        <Link to="/addaddress" className="btn btn-red btn-block btn-ssm font-14 text-upper ft-wt-400 w-100 br-4 mb-05">Add New Address</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade dialogbox" id="ModalAlert2" data-backdrop="static" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-icon text-success">
                                    <ion-icon name="checkmark-circle"></ion-icon>
                                </div>
                                <div className="modal-body"> 
                                    Address Details Deleted Successfully 
                                </div>
                                <div className="modal-footer">
                                    <div className="btn-inline"> 
                                        <button type="button" className="btn" data-dismiss="modal">Ok</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
  
export default CustomerAddresses;