import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import Loader from './Loader';
import AppHeader from './AppHeader';
import GoogleLogin from 'react-google-login';

import $ from "jquery";

const responseGoogle = (response) => {
    console.log(response);
}
  
class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            LOADING: true, 
            Notifications: false,
            CUSTOMER_DET: '',
            showGoogle: true,
            google_id: ''
        };
    }


	loginGoogleUser = (response) => {

		let profileObj = response.profileObj;
        let googleID = response.googleId;
		var userObject = {
							mode:"GOOGLE",
							googleId: response.googleId,
							accessToken:response.accessToken,
							email:profileObj.email,
							lastName:profileObj.familyName,
							firstName:profileObj.givenName,
							profileImage: profileObj.imageUrl,
							fullName: profileObj.name,
                            webpushr_id:localStorage.getItem('WEBPUSHR_ID')
						};

		localStorage.setItem('userObject', JSON.stringify(userObject));
		var userData = localStorage.getItem('userObject');
		var userDataJson = JSON.parse(userData);

		const requestOptions = {
        	method: 'POST',
        	headers: { 'Content-Type': 'application/json' },
        	body: JSON.stringify(userObject)
    	};

        var that = this;
		// fetch check if google id/email id exists
		fetch(global.config.bURL+'register_api.php', requestOptions)
			.then(function(response) {
				return response.json();
			})
			.then(function(result) {
                let notify = '';
                if(result.err!=='' && result.err!==undefined)
                    notify = result.err;

                let logged = false;

                let customer = result.customer;
                if(customer!=='' && customer!==undefined)
                {
                    localStorage.setItem('userData',customer);
                    logged = true;
                }

                let newMEMBER = result.newMEMBER;
                if(newMEMBER==='Y')
                {
                    $('#google_id').val(googleID);
                    $('#txtname').val(profileObj.name);
                    $('#txtemail').val(profileObj.email);

                    that.setState({ showGoogle: false, google_id:googleID });    
                }

                that.setState({ Notifications: notify });
                if(notify!=='')
                    window.$('#ModalAlert').modal('show');
			});
	}
    
    handleSubmit = (e) => {
        e.preventDefault();

        let err = 0;
        if ($('#frmRegister')[0].checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            err++;
        }
        $('#frmRegister')[0].classList.add('was-validated');

        const googleID = $('#google_id').val().trim();

        if(googleID=='')
        {
            const password = $('#txtpassword').val().trim();
            const password2 = $('#txtpassword2').val().trim();

            if(password!==password2)
            {
                err++;
                $('#txtpassword2').val('');
            }
        }

        if(err===0)
        {
            if(googleID=='')
            {
                var pass = $('#txtpassword').val();
                $('#txtpassword').val(window.b64_md5(pass));

                var pass2 = $('#txtpassword2').val();
                $('#txtpassword2').val(window.b64_md5(pass2));
            }

            $('#submitLoading').css('display','block');
            
            const method = "POST";
            const body = new FormData(this.form);
            //console.log(body);
            var that = this;
            fetch(global.config.bURL+'register_api.php', { method, body })
            .then(function (response) {
                //console.log(response);
                return response.json();
            })
            .then(function (result) {

                //console.log(result);
                //alert(result);
                let notify = '';
                if(result.err!=='' && result.err!==undefined)
                    notify = result.err;

                let customer = result.customer;
                if(customer!=='' && customer!==undefined)
                {
                    localStorage.setItem('userData',customer);
                }

                that.setState({ Notifications: notify});
                $('#submitLoading').css('display','none');
                if(notify!=='')
                    window.$('#ModalAlert').modal('show');
          });
        }
    }

    handleClickGetTerms = () => {
        window.$('#ModalTerms').modal('show');
    }

    componentDidMount() {
        let mount = $("div").hasClass("modal-backdrop");
        if(mount===true)
        {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
            //window.location.reload(true);
        }

    	this.setState({
    		LOADING: false,
    	});
    }
    
    render() { 

		if(localStorage.getItem('userData')) {
            if(localStorage.getItem('cartData'))
                return (<Redirect to={'/cart'}/>)
            else
			    return (<Redirect to={'/home'}/>)
		}
        
        let AppHeaderClass = 'nkis-header appHeader no-shadow';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = '/login';
    	let AppHeaderTitle = 'Register';
    	let AppHeaderIMAGE = '';
    	let AppHeaderRight = '';
    	let AppHeaderRightLink = '';
    
        return (
            <div>
                {this.state.LOADING && <Loader />}
	    	    <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderIMAGE={AppHeaderIMAGE} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
                <div id="appCapsule">
                    <div className="login-form mt-1 pl-2 pr-2">
                        <div className="section mt-6 mb-5 pt-3">
                            {this.state.showGoogle &&
                                <div>
                                    {/*<Link to="">
                                        <div className="with-google btn btn-outline-danger bw-2 bg-white btn-lg btn-block bt-rel font-14 text-upper text-grey mb-1 bt-ht-50">
                                            <div className="outer-circle bg-google">
                                                <i className="fa fa-google-plus" aria-hidden="true"></i>
                                            </div>
                                            Register using Google
                                        </div>
                                    </Link>*/}
                                    <div className="with-google2">
                                        <GoogleLogin 
                                            clientId="202481004765-nifnb1i75832bb6it98e7cptr1sehtsr.apps.googleusercontent.com"
                                            buttonText="REGISTER USING GOOGLE"
                                            cookiePolicy={'single_host_origin'}
                                            onSuccess={this.loginGoogleUser}
                                            onFailure={responseGoogle}
                                            className="outer-circle mar-auto bg-google google-nkis"
                                            style={{width:'100%'}}
                                        />
                                    </div>
                                    <br /><br />
                                    <div className="mt-2 mb-2">
                                        <p>OR</p>
                                    </div>
                                </div>
                            }
                            <form id="frmRegister" ref={el => (this.form = el)} onSubmit={this.handleSubmit} className="needs-validation" noValidate>
                                <input type="hidden" value="REGISTER" className="form-control" name="mode" id="mode"  />
                                <input type="hidden" defaultValue={localStorage.getItem('WEBPUSHR_ID')} className="form-control" name="webpushr_id" id="webpushr_id"  />
                                <input type="hidden" defaultValue={this.state.google_id} className="form-control" name="google_id" id="google_id"  />
                                <div className="form-group boxed mb-1">
                                    <div className="input-wrapper">
                                        <input type="text" className="form-control font-14" name="txtname" id="txtname" required placeholder="Name" autoComplete="off"/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div> 
                                        <div className="invalid-feedback">Please enter your name.</div>
                                    </div>
                                </div>
                                <div className="form-group boxed mb-1">
                                    <div className="input-wrapper">
                                        <input type="email" className="form-control font-14" name="txtemail" id="txtemail" required placeholder="Email" autoComplete="off"/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div> 
                                        <div className="invalid-feedback">Please enter your email id.</div>
                                    </div>
                                </div>
                                <div className="form-group boxed mb-1">
                                    <div className="input-wrapper">
                                        <input type="text" className="form-control font-14" name="txtmobile" id="txtmobile" required placeholder="Phone" autoComplete="off"/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div> 
                                        <div className="invalid-feedback">Please enter your mobile no.</div>
                                    </div>
                                </div>
                                <div className="form-group boxed mb-1">
                                    <div className="input-wrapper">
                                        <input type="text" className="form-control font-14" name="txtlabel" id="txtlabel" required placeholder="Label: Work, Office, Home, Other..." autoComplete="off"/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div> 
                                        <div className="invalid-feedback">Please enter label.</div>
                                    </div>
                                </div>
                                <div className="form-group boxed mb-1">
                                    <div className="input-wrapper">
                                        <input type="text" className="form-control font-14" name="txtaddress" id="txtaddress" required placeholder="Address Line 1" autoComplete="off"/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div> 
                                        <div className="invalid-feedback">Please enter your address line 1.</div>
                                    </div>
                                </div>
                                <div className="form-group boxed mb-1">
                                    <div className="input-wrapper">
                                        <input type="text" className="form-control font-14" name="txtaddress2" id="txtaddress2" required placeholder="Address Line 2" autoComplete="off"/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div> 
                                        <div className="invalid-feedback">Please enter your address line 2.</div>
                                    </div>
                                </div>
                                <div className="form-group boxed mb-1">
                                    <div className="input-wrapper">
                                        <input type="text" className="form-control font-14" name="txtlandmark" id="txtlandmark" required placeholder="Landmark" autoComplete="off"/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div> 
                                        <div className="invalid-feedback">Please enter landmark.</div>
                                    </div>
                                </div>
                                {/*<div className="form-group boxed mb-1">
                                    <div className="input-wrapper">
                                        <textarea rows="3" className="form-control font-14" name="txtaddress" id="txtaddress" placeholder="Address" required></textarea>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div> 
                                        <div className="invalid-feedback">Please enter your address.</div>
                                    </div>
                                </div>*/}
                                <div className="form-group boxed mb-1">
                                    <div className="divide-50l">
                                        <div className="input-wrapper">
                                            <input type="text" className="form-control font-14" name="txtcity" id="txtcity" required placeholder="City" autoComplete="off"/>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            <div className="valid-feedback">&nbsp;</div> 
                                            <div className="invalid-feedback">Please enter your city.</div>
                                        </div>
                                    </div>
                                    <div className="divide-50r">
                                        <div className="input-wrapper">
                                            <input type="text" className="form-control font-14" name="txtstate" id="txtstate" required placeholder="State" autoComplete="off"/>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            <div className="valid-feedback">&nbsp;</div> 
                                            <div className="invalid-feedback">Please enter your state.</div>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="form-group boxed mb-1">
                                    <div className="divide-50l">
                                        <div className="input-wrapper">
                                            <input type="text" className="form-control font-14" name="txtcountry" id="txtcountry" required placeholder="Country" autoComplete="off" value="India" readOnly />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            <div className="valid-feedback">&nbsp;</div> 
                                            <div className="invalid-feedback">Please enter your country.</div>
                                        </div>
                                    </div>
                                    <div className="divide-50r">
                                        <div className="input-wrapper">
                                            <input type="text" className="form-control font-14" name="txtpincode" id="txtpincode" required placeholder="PinCode" autoComplete="off"/>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            <div className="valid-feedback">&nbsp;</div> 
                                            <div className="invalid-feedback">Please enter your pincode.</div>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                {this.state.showGoogle &&
                                    <div className="form-group boxed mb-1">
                                        <div className="divide-50l">
                                            <div className="input-wrapper">
                                                <input type="password" className="form-control font-14" name="txtpassword" id="txtpassword" minLength="8" required placeholder="Password" autoComplete="off"/>{/* pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" */}
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                                <div className="valid-feedback">&nbsp;</div> 
                                                <div className="invalid-feedback">Must contain at least 8 or more characters.</div>
                                            </div>
                                        </div>
                                        <div className="divide-50r">
                                            <div className="input-wrapper">
                                                <input type="password" className="square-inputbox form-control" name="txtpassword2" id="txtpassword2" minLength="8" required placeholder="Confirm Password" autoComplete="off"/> {/*pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"  */}
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                                <div className="valid-feedback">&nbsp;</div> 
                                                <div className="invalid-feedback">Must contain at least 8 or more characters.</div>
                                            </div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                }
                                <div className="form-group boxed mb-1 terms-check">
                                    <div className="input-wrapper custom-control custom-checkbox mb-1">
                                        <input type="checkbox" className="custom-control-input" value="Y" id="chkterms" name="chkterms" required />
                                        <label className="custom-control-label" htmlFor="chkterms">I Agree to <Link
                                    to="#" onClick={()=>{this.handleClickGetTerms()}}>Terms &amp; Conditions</Link>
                                        </label>
                                    </div>
                                    <div className="valid-feedback">&nbsp;</div> 
                                    <div className="invalid-feedback">Please select the checkbox.</div>                                
                                </div>
                                <div className="form-group boxed">
                                    <button type="submit" className="btn btn-red btn-block btn-lg font-14 text-upper ft-wt-400 w-100 br-4 mb-05">
                                    <span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{display: 'none'}} role="status" aria-hidden="true"></span>
                                        Register Now</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="modal fade modalbox" id="ModalTerms" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Terms &amp; Conditions</h5>
                                <Link to="javascript:;" data-dismiss="modal">Close</Link> 
                            </div>
                            <div className="modal-body p-0">
                                <ol className="tnc m-1 pl-2 pr-2 font-size-12" style={{'textAlign':'left'}}>
                                    <li className="mb-1">We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade dialogbox" id="ModalAlert" data-backdrop="static" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-icon text-danger">
                                <ion-icon name="close-circle"></ion-icon>
                            </div>
                            <div className="modal-body"> 
                                {this.state.Notifications} 
                            </div>
                            <div className="modal-footer">
                                <div className="btn-inline"> 
                                    <button type="button" className="btn" data-dismiss="modal">Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
      
export default Register;