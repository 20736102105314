import React from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';
import Loader from './Loader';
import AppHeader from './AppHeader';
import BottomMenu from './BottomMenu';
import SidebarPanel from './SidebarPanel';

import $ from "jquery";

class ORDERS extends React.Component {
    constructor(props) {
        //console.log(props);
        super(props);
        this.state = {
            LOCATION: this.props.location.pathname,
            LOADING: true,
            ORDERS_LIST: [],
            OrdersExist: 'N',
            CUSTOMER_DET: '',
            TOTAL_ORDERS: 0,
            FAVOURITES: 0,
            customerid: localStorage.getItem('userData'),
            page: 1,
            displayLoadMore: 'N',
        };
    }

    GetCustomerOrderList() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'ORDERS_LIST', customer: this.state.customerid})
        };

        fetch(global.config.bURL+"orders_list.php", requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {
            //console.log(result);
            
            let customer_orders = result.ORDERS;
            if(customer_orders==='' || customer_orders===undefined)
                customer_orders = '';

            let OrdersExist = 'Y';
            if(customer_orders==='')
                OrdersExist = 'N';

            let FAVOURITES = result.FAVOURITES;
            var CUSTOMER_DET = 'Retail~~**~~Hi User';
            if(result.CUSTOMER!=='' && result.CUSTOMER!==undefined && result.CUSTOMER!=='undefined')
                CUSTOMER_DET = result.CUSTOMER;
                            
            this.setState({ 
                LOADING:false, 
                ORDERS_LIST:customer_orders, 
                TOTAL_ORDERS: result.TOTAL_ORDERS,
                page: result.page,
                displayLoadMore: result.LOADMORE,
                FAVOURITES: FAVOURITES, 
                OrdersExist:OrdersExist, 
                CUSTOMER_DET:CUSTOMER_DET 
            });
        });
    }

    fetchSecond(page) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'ORDERS_LIST', page:page, customer: this.state.customerid})
        };

        fetch(global.config.bURL+"orders_list.php", requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {

            var CUSTOMER_DET = 'Retail~~**~~Hi User';
            if(result.CUSTOMER!=='' && result.CUSTOMER!==undefined && result.CUSTOMER!=='undefined')
                CUSTOMER_DET = result.CUSTOMER;
    
            this.setState({ 
                LOADING:false, 
                ORDERS_LIST:this.state.ORDERS_LIST.concat(result.ORDERS),
                page: result.page,
                displayLoadMore: result.LOADMORE,
                CUSTOMER_DET:CUSTOMER_DET 
            });
        });
    }

    componentDidMount() {
        let mount = $("div").hasClass("modal-backdrop");
        if(mount===true)
        {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
            //window.location.reload(true);
        }

        this.GetCustomerOrderList();
    }

    loadMore = () => {
        this.fetchSecond(this.state.page);
    };

    render() { 

        let logged = 'N';
        if(localStorage.getItem('userData'))
            logged = 'Y';

        if (logged==='N')
        {
            if(localStorage.getItem('cartData'))
                return (<Redirect to={'/cart'}/>)
            else
                return (<Redirect to={'/home'}/>)
        }

    	let AppHeaderClass = 'nkis-header appHeader no-shadow';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = '/profile';
    	let AppHeaderTitle = 'Order Listing';
    	let AppHeaderIMAGE = '';
    	let AppHeaderRight = 'showLINK';
    	let AppHeaderRightLink = '';

        let Orders = this.state.ORDERS_LIST.map((post, i)=>{

            let ItemList = '';
            post.ITEMS_DET.map((post2)=>{
                ItemList += post2.QTY+' '+post2.PROD+', ';
            })

            if(ItemList!=='')
                ItemList = ItemList.substring(0, ItemList.length-2);
            
            let url = (post.STATUS2==='D' || post.STATUS2==='C')?'orderdetails':'ordertrack';

            return (
                <li key={post.ID}> 
                    <Link className="w-100" to={`/${url}/${post.ID}`}>
                        <div className="item">
                            <div className="in">
                                <div className="item-name w-80 pr-1 text-grey4 font-13 ft-wt-500 pb-05">
                                    <p className="mb-0 text-grey5 pb-02 ft-wt-600"> Order ID {post.ID}</p>
                                    <p className="mb-0 text-grey1 pb-02">{post.DATE}</p>

                                    <p className="title-cut-sub2">{ItemList}</p>
                                    <div className="cat-price pt-05">
                                        <p className="mb-0 final-price text-dblue font-15 ft-wt-400 text-upper pr-05"> &#8377; {post.TOTAL} </p>
                                    </div>
                                </div>
                                <span className="badge badge-light font-10 ft-wt-400 cat-price ht-20 text-upper text-orange">{post.STATUS}</span>
                            </div>
                        </div>
                    </Link> 
                </li>
            );
        });


        return (
            <div>
                {this.state.LOADING && <Loader />}
                <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderIMAGE={AppHeaderIMAGE} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
                <div id="appCapsule">
                    <div className="category-listing mt-1">
                        {this.state.TOTAL_ORDERS>0 && <div className="section-title font-size-17 mb-0 text-sdgrey pr-2 pl-2 pt-1 pb-1 text-upper text-grey4">{this.state.TOTAL_ORDERS} Order{this.state.TOTAL_ORDERS>1?'s':''} </div>}
                        <div className="section full mt-0">
                            <div className="cart-list-items">
                                { this.state.OrdersExist==='Y' &&
                                    <ul className="listview image-listview">
                                        {Orders}
                                    </ul>
                                }
                                {this.state.displayLoadMore==='Y' && <p className="mr-1 mb-1 mt-2 ml-1">
                                    <button type="button" style={{'height': '31px'}} onClick={() => this.loadMore()} className="btn newshadowed font-size-11 btn-danger btn-ssm btn-block goBack text-upper br-4">Load More</button>
                                </p>}
                                {this.state.OrdersExist==='N' && 
                                    <div className="section pt-6" style={{textAlign: 'center'}}>
                                        <img src="/assets/img/no-orders.png" alt="no-orders" className="form-image imaged w200" />
                                    </div>
                                }    
                            </div>
                        </div>
                    </div>
                </div>
                <BottomMenu LOCATION={this.state.LOCATION} />
                {this.state.CUSTOMER_DET && <SidebarPanel CUSTOMER_DET={this.state.CUSTOMER_DET}/>}
            </div>
        );
    }
}
  
export default ORDERS;