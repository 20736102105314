import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Index from './components/Index';
import Home from './components/Home';
import Categories from './components/Categories';
import Category from './components/Category';
import Items from './components/Items';
import Item from './components/Item';
import Cart from './components/Cart';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import Register from './components/Register';
import ChangeAddress from './components/ChangeAddress';
import AddAddress2 from './components/AddAddress2';
import Profile from './components/Profile';
import CustomerAddresses from './components/CustomerAddresses';
import AddAddress from './components/AddAddress';
import CustomerAddressDetails from './components/CustomerAddressDetails';
import ChangePassword from './components/ChangePassword';
import Favourites from './components/Favourites';
import Checkout from './components/Checkout';
import SubmitOrder from './components/SubmitOrder';
import OnlinePay from './components/OnlinePay';
import SearchItems from './components/SearchItems';
import CustomerOrders from './components/CustomerOrders';
import CustomerOrderTrack from './components/CustomerOrderTrack';
import CustomerOrdersDetails from './components/CustomerOrdersDetails';
import Brands from './components/Brands';
import Brand from './components/Brand';

const NotFoundPage = () => (
    <div className="news-content" id="adver-page">
        <div className="container">
            <div className="ad-box not-found">
                <h1>404 Page Not Found</h1>
                <p>Sorry, the page you are looking for could not be found</p>
            </div>
        </div>
    </div>
);

const Routes = () => (
	<BrowserRouter>
		<Switch>
			<Route path="/" component={Index} exact={true} />
			<Route path="/home" component={Home} exact={true} />
			<Route path="/categories" component={Categories} exact={true} />
			<Route path='/category/:id' component={Category} exact={true} />
			<Route path='/items/:id' component={Items} exact={true} />
			<Route path='/item/:id' component={Item} exact={true} />
			<Route path='/item/:type/:id' component={Item} exact={true} />
			<Route path='/cart' component={Cart} exact={true} />
			<Route path='/login' component={Login} exact={true} />
			<Route path='/forgot-password' component={ForgotPassword} exact={true} />
			<Route path='/register' component={Register} exact={true} />
			<Route path='/changeaddress' component={ChangeAddress} exact={true} />
			<Route path='/addaddress2' component={AddAddress2} exact={true} />
			<Route path='/profile' component={Profile} exact={true} />
			<Route path='/addresses' component={CustomerAddresses} exact={true} />
			<Route path='/addaddress' component={AddAddress} exact={true} />
			<Route path='/addressdetails/:id' component={CustomerAddressDetails} exact={true} />
			<Route path='/changepassword' component={ChangePassword} exact={true} />
			<Route path='/favourites' component={Favourites} exact={true} />
			<Route path='/checkout' component={Checkout} exact={true} />
			<Route path='/submit_order' component={SubmitOrder} exact={true} />
			<Route path='/pay/:id' component={OnlinePay} exact={true} />
			<Route path='/search' component={SearchItems} exact={true} />
			<Route path='/search/:search_text' component={SearchItems} exact={true} />
			<Route path='/orders' component={CustomerOrders} exact={true} />
			<Route path='/ordertrack/:id' component={CustomerOrderTrack} exact={true} />
			<Route path='/ordertrack/:id/:showModal' component={CustomerOrderTrack} exact={true} />
			<Route path='/orderdetails/:id' component={CustomerOrdersDetails} exact={true} />
			<Route path="/brands" component={Brands} exact={true} />
			<Route path='/brand/:id' component={Brand} exact={true} />
			<Route component={NotFoundPage} />
		</Switch>
	</BrowserRouter>
);
export default Routes;