import React, { Component } from 'react';
import AppHeader from './AppHeader';
import Loader from './Loader';
import HomeCategory from './HomeCategory';
import HomeSlider from './HomeSlider';
import HomeNewItems from './HomeNewItems';
import HomeItems from './HomeItems';
import HomeTopSellerItems from './HomeTopSellerItems';
import BottomMenu from './BottomMenu';
import SidebarPanel from './SidebarPanel';

import $ from "jquery";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LOCATION: this.props.location.pathname,
        	LOADING: true,
            CATEGORY: [],
            SLIDER: [],
            ITEMS: [],
            NEW_ITEMS: [],
            TOPSELLER_ITEMS: [],
            FAVOURITES: 0,
            CUSTOMER_DET: '',
            customerid: localStorage.getItem('userData'),
        }
    }

    GetHomeDetails()
    {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'HOME', customer: this.state.customerid})
        };

        fetch(global.config.bURL+"home.php", requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {

                let CATEGORY = result.CATEGORY;
                let SLIDER = result.SLIDER;
                let ITEMS = result.ITEMS_FEATURED;
                let NEW_ITEMS = result.ITEMS_NEW;
                let TOPSELLER_ITEMS = result.ITEMS_TOPSELLER;
                let FAVOURITES = result.FAVOURITES;
                var CUSTOMER_DET = 'Retail~~**~~Hi User';
                if(result.CUSTOMER!=='' && result.CUSTOMER!==undefined && result.CUSTOMER!=='undefined')
                    CUSTOMER_DET = result.CUSTOMER;
                
                localStorage.setItem('nkis_favourites', FAVOURITES);

                this.setState({ 
                	CATEGORY:CATEGORY, 
                	SLIDER: SLIDER, 
                	ITEMS: ITEMS,
                	NEW_ITEMS: NEW_ITEMS,
                	TOPSELLER_ITEMS: TOPSELLER_ITEMS,
                    FAVOURITES: FAVOURITES, 
                    CUSTOMER_DET: CUSTOMER_DET,
                	LOADING: false, 
                });
            });
    }

    componentDidMount() {
    	let mount = $("div").hasClass("modal-backdrop");

    	if (mount === true) {
    	    $('body').removeClass('modal-open');
    	    $(".modal-backdrop").remove();
    	    //window.location.reload(true);
    	} 

        this.GetHomeDetails();
    }

    render() {

    	let AppHeaderClass = 'nkis-header appHeader no-shadow';
    	let AppHeaderLeft = '';
    	let AppHeaderLeftLink = '';
    	let AppHeaderTitle = 'Home';
    	let AppHeaderIMAGE = '/assets/images/nkis.png';
    	let AppHeaderRight = 'showLINK';
    	let AppHeaderRightLink = '';
        
    	return(
    	    <div className="bg-peach">
	    	    {this.state.LOADING && <Loader />}
	    	    <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderIMAGE={AppHeaderIMAGE} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
	    	    <div id="appCapsule">
                    <div className="category-listing login-form mt-1">
                        <div className="section pt-1 pb-0 pr-0 pl-0 item-tab">
                            {/* TOP CATEGORIES*/}
                            {this.state.CATEGORY.length>0 && <HomeCategory category={this.state.CATEGORY} />}
                            {/* SLIDER*/}
                            {this.state.SLIDER.length>0 && <HomeSlider slider={this.state.SLIDER} />}
                            {/* NEW ITEMS*/}
                            {this.state.NEW_ITEMS.length>0 && <HomeNewItems items={this.state.NEW_ITEMS} />}
                            {/* FEATURED ITEMS*/}
                            {this.state.ITEMS.length>0 && <HomeItems items={this.state.ITEMS} />}
                            {/* TOP SELLING ITEMS*/}
                            {this.state.TOPSELLER_ITEMS.length>0 && <HomeTopSellerItems items={this.state.TOPSELLER_ITEMS} />}
                        </div>
                    </div>
                </div>
                <BottomMenu LOCATION={this.state.LOCATION} />
                {this.state.CUSTOMER_DET && <SidebarPanel CUSTOMER_DET={this.state.CUSTOMER_DET}/>}
	    	</div>
    	)
    }
}
export default Home;