import React from 'react';
import {Link} from 'react-router-dom';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


const HomeSlider = (props) => {

    let Slider = props.slider.map((post, i)=>{
        return (
            <div className="item p-0 exercise" key={post.ID}>
                {post.LINK!=='' && <Link to={post.LINK}><img src={post.IMAGE} alt={post.ALT} className="imaged br-20 w-100 mb-4" /></Link>}
                {post.LINK==='' && <img src={post.IMAGE} alt={post.ALT} className="imaged br-20 w-100 mb-4" />}
            </div>
        );
    });

    const options = {
        items: 1,
        nav: false,
        loop: false,
        dots: true,
    };

    return (
        <div className="section full mt-4 m-2">
            <div className="nkis-carousel dash-carousel carousel-slider owl-carousel owl-theme">
                <OwlCarousel className="owl-theme" {...options}>
                    {Slider}
                </OwlCarousel>
            </div>
        </div>
    );
}
  
export default HomeSlider;