import React from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';
import AppHeader from './AppHeader';

import $ from "jquery";


class OnlinePay extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        LOADING: true, 
        id: props.match.params.id,
        orderTOTAL: 0,
        cusNAME: '',
        cusEMAIL: '',
        cusPHONE: '',
        cusADDRESS: '',
        payment_amount: 0,
        refund_id: 0,
        ORDER_STATUS: '',
        customerid: localStorage.getItem('userData'),
      };
  
      this.paymentHandler = this.paymentHandler.bind(this);
      this.refundHandler = this.refundHandler.bind(this);
    }

    fetchCustomerOrders() {

      const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'ORDER_SUMMARY', id:this.state.id, customer: this.state.customerid})
      };
      
      fetch(global.config.bURL+"order_summary.php", requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {
          //console.log(result);
          
          this.setState({ orderTOTAL:result.total, cusNAME:result.name, cusEMAIL:result.email, cusPHONE:result.phone, cusADDRESS:result.address });
          if(result.total!=0)
            this.paymentHandler();
      });
    } 
    
    componentDidMount() {
      let mount = $("div").hasClass("modal-backdrop");
      if(mount===true)
      {
          $('body').removeClass('modal-open');
          $(".modal-backdrop").remove();
      }

      this.fetchCustomerOrders();
    }

    UpdateCustomerOrders2(order_id, a, b) {
      b = b * 100;
      let mode = 'UPDATE';
      let customer = '';
      if(localStorage.getItem('userData'))
          customer = localStorage.getItem('userData');

      var that = this;
      fetch(global.config.bURL+'order_update.php?mode='+mode+'&customer='+this.state.customerid+'&id='+this.state.id+'&a='+a+'&b='+b,{
          mode: 'cors',
          credentials: 'same-origin',
          headers: {
              'Access-Control-Allow-Origin': '*', 
              Accept: 'application/json', 
              'Content-Type': 'application/json',
          },
      }).then(function (response) {
          return response.json();
      }).then(function (result) {
          console.log(result);
          
          let orderSTATUS = 'FAILURE';
          let ag = result.status;
          if(ag=='captured')
          {
            orderSTATUS = 'SUCCESS';
            localStorage.removeItem('cartData');
          }
          else if(ag=='authorized')
          {
            orderSTATUS = 'SUCCESS';
            localStorage.removeItem('cartData');
          }

          that.setState({ LOADING:false, ORDER_STATUS:orderSTATUS });
        });
    } 
    
    paymentHandler = () => {
      const payment_amount = this.state.orderTOTAL;
      const self = this;
      const options = 
      {
        key: 'rzp_test_hPOFBPBw5gxiY7',
        receipt: this.state.id,
        amount: payment_amount*100,
        name: 'NKIS OrderID: '+this.state.id,
        description: 'Order Payment for OrderID: '+this.state.id,
  
        handler(response) {
          console.log(response);
          
          const paymentId = response.razorpay_payment_id;
          self.UpdateCustomerOrders2(self.state.id,paymentId,payment_amount);


          const url = global.config.bURL+'/v1/rzp_capture/'+paymentId+'/'+payment_amount;
          //const url = 'http://localhost:3000/api/v1/rzp_capture/'+paymentId+'/'+payment_amount;
          console.log(url);
          
          // Using my server endpoints to capture the payment
        },
  
        prefill: {
          name: this.state.cusNAME,
          email: this.state.cusEMAIL,
          contact: this.state.cusPHONE,
        },
        notes: {
          address: this.state.cusADDRESS,
        },
        theme: {
          color: '#EC4433',
        },
      };
      const rzp1 = new window.Razorpay(options);
  
      rzp1.open();
    }
  
    refundHandler(e) {
      e.preventDefault();
      const { refund_id } = this.state;
      const url = process.env.URL+'/api/v1/rzp_refunds/'+refund_id;
  
      // Using my server endpoints to initiate the refund
      fetch(url, {
        method: 'get',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
      .then(resp =>  resp.json())
      .then(function (data) {
        console.log('Request succeeded with JSON response', data);
        alert("Refund Succeeded", )
      })
      .catch(function (error) {
        console.log('Request failed', error);
      });
  
    }

    render() {

      let AppHeaderClass = 'nkis-header appHeader no-shadow';
    	let AppHeaderLeft = '';
    	let AppHeaderLeftLink = '';
    	let AppHeaderTitle = 'PAYNOW';
    	let AppHeaderIMAGE = '';
    	let AppHeaderRight = '';
    	let AppHeaderRightLink = '';
      
    
      if(this.state.ORDER_STATUS == 'SUCCESS')
      {
          return (<Redirect to={`/ordertrack/${this.state.id}/Y`} />)
      }
    
      if(this.state.ORDER_STATUS == 'FAILURE')
      {
          return (<Redirect to='/failure' />)
      }

      return (
        <div>
	    	    <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderIMAGE={AppHeaderIMAGE} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
            <div id="appCapsule">
              <div>
                <br />
                <br />
                <br />
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-8 align-content-center">
                    <center>
                      <h4>Please do not refresh or click the back button.</h4>
                      <br />
                      <button className="btn btn-outline-primary" type="button" disabled="">
                        <span className="spinner-border spinner-border-sm mr-05" role="status" aria-hidden="true"></span>
                        Loading...
                      </button>
                    </center>
                  </div>
                  <div className="col-2"></div>
                </div>
              </div>
            </div>
        </div>
    );
    }
  }
  
  export default OnlinePay;