import React, { Component } from 'react';

class Loader extends Component {

    render() {
        return (
            <div id="loader">
                <div className="spinner-border text-primary" role="status"></div>
            </div>
        );
    }
}

export default Loader;