import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import Loader from './Loader';
import AppHeader from './AppHeader';
import {currencyFormat} from './Common.js';

import $ from "jquery";

const OrdersItem = (props) => {
    return (
        props.items.map((post2)=>{
                return (
                    <li key={post2.ID}>
                        <div className="item"> 
                            <div className="title-cut-sub text-left text-grey4 font-13 ft-wt-500 pb-05 prod-name"> {post2.PROD}</div>
                            <div className="in prod-qty-total">
                                <div className="item-name font-12 pr-1">
                                    <div className="add-btn add-btn-cart tp-neg-7 add-btn-inc mt-0 text-center font-11 ft-wt-400"> &#8377; {post2.RATE2} X {post2.QTY} </div>
                                </div>
                                <span className="badge badge-light mt-0 font-15 ft-wt-400">&#8377; {post2.TOTAL}</span>
                            </div>
                        </div>
                    </li>

                )                
        })
    );
}

const OrdersItemList = (props) => {
    return (
        props.orders.map((post,i)=>{
            return (<OrdersItem key={i} items={post.ITEMS_DET} />);
        })
    );
}

const CessCharges = (props) => {
    return (
        props.cessItems.map((post2,i) => {
            return(
                <ul className="item-total listview image-listview" key={i}>
                    <li>
                        <div className="item font-size-13 font-weg-300">
                            <div className="in">
                                <div className="item-name"> CESS </div>
                                <div className="item-qty"> {post2.PERC}% </div>
                                <span className="badge badge-light font-size-13 font-weg-300">&#8377; {post2.VALUE}</span>
                            </div>
                        </div>
                    </li> 
                </ul>
            );
        })
    );    
}

const TaxCharges = (props) => {
    return (
        props.taxItems.map((post2,i) => {
            return(
                <ul className="item-total listview image-listview" key={i}>
                    <li>
                        <div className="item font-size-13 font-weg-300">
                            <div className="in">
                                <div className="item-name"> SGST </div>
                                <div className="item-qty"> {post2.PERC}% </div>
                                <span className="badge badge-light font-size-13 font-weg-300">&#8377; {post2.SGST}</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="item font-size-13 font-weg-300">
                            <div className="in">
                                <div className="item-name"> CGST </div>
                                <div className="item-qty"> {post2.PERC}% </div>
                                <span className="badge badge-light font-size-13 font-weg-300">&#8377; {post2.CGST}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            );
        })
    );    
}

const OrdersTotalBreakUp = (props) => {
    return (
        props.orders.map((post,i)=>{
            return (
                <div key={i}>
                    { post.TAX!=='0.00' && post.TAX>0 &&
                        <li className="cart-list-head">
                            <div className="section-title w-100 font-13 text-upper d-flex justify-content-between pt-2 pb-2 pr-2 pl-2 text-grey4"> Inclusive Taxes Breakups
                                <span className="ft-wt-500">&#8377; {currencyFormat(post.TAX)}</span>
                            </div>
                        </li>
                    }
                    { post.DISCOUNT!=='0.00' && post.DISCOUNT>0 &&
                        <li className="cart-list-head">
                            <div className="section-title w-100 font-13 text-upper d-flex justify-content-between pt-2 pb-2 pr-2 pl-2 text-grey4"> Inclusive Taxes Breakups
                                <span className="ft-wt-500">&#8377; {currencyFormat(post.DISCOUNT)}</span>
                            </div>
                        </li>
                    }
                    { post.TOTAL!=='0.00' && post.TOTAL>0 &&
                        <li className="cart-list-head">
                            <div className="section-title w-100 font-13 text-upper d-flex justify-content-between pt-2 pb-2 pr-2 pl-2 text-grey4"> Total
                                <span className="ft-wt-500">&#8377; {currencyFormat(post.TOTAL)}</span>
                            </div>
                        </li>
                    }
                </div>
            );
        })
    )
}

const OrderAddress = (props) => {
    return (
        props.orders.map((post,i)=>{
            return (
                <div className="wide-block loc-details" key={i}>
                    <p align="left" className="labbbel">{post.LABEL}</p>
                    <p align="left">{post.ADDRESS}</p>
                    {post.ADDRESS2 && <p align="left">{post.ADDRESS2}</p>}
                    {post.ADDRESS3 && <p align="left">{post.ADDRESS3}</p>}
                </div>
            );
        })
    )
}

/*<button key={i} type="button" className={`btn btn-${post.STATUS_CSS} btn-block`}>{post.STATUS}</button>*/

const OrderStatus = (props) => {
    return (
        props.orders.map((post,i)=>{
            return (
                <span key={i} className={`badge badge-${post.STATUS_CSS}`}>{post.STATUS}</span>
            );
        })
    )
}

const OrderInstructions = (props) => {
    return (
        props.orders.map((post,i)=>{
            return (
                <div className="row" style={{width: '100%'}} key={i}>
                    {post.INSTRUCTIONS!=='' &&
                        <div className="col-12">
                            <div className="div-instruction">
                                <ion-icon name="chatbox-ellipses-outline"></ion-icon>
                                &nbsp; Special Instructions: <br />
                                <p key={i} className="p-instruction">
                                {post.INSTRUCTIONS}
                                </p>
                            </div>
                        </div>    
                    }
                </div>
            );
        })
    )
}

const OrderCancellationReason = (props) => {
    return (
        props.orders.map((post,i)=>{
            return (
                <div className="row" style={{width: '100%'}} key={i}>
                    {post.CANCELLEDREASON!=='' &&
                        <div className="col-12">
                            <div className="div-instruction">
                                <ion-icon name="chatbox-ellipses-outline"></ion-icon>
                                &nbsp; Reason For Cancellation: <br />
                                <p key={i} className="p-instruction">
                                {post.CANCELLEDREASON}
                                </p>
                            </div>
                        </div>    
                    }
                </div>
            );
        })
    )
}

class CustomerOrdersDetails extends React.Component {
    constructor(props) {
        //console.log(props);
        super(props);
        this.state = {
            LOADING: true, 
            id: props.match.params.id,
            CustomerOrders: [],
            CustomerTax: [],
            CustomerCess: [],
            Notifications: '',
            SUCCESS: '',
            customerid: localStorage.getItem('userData')
        };
    }

    GetCustomerOrderDetails() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'ORDERS', id: this.state.id, customer: this.state.customerid})
        };

        fetch(global.config.bURL+"order.php", requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {
            //console.log(result);
            
            let customer_orders = result.orders;
            if(customer_orders==='' || customer_orders===undefined)
                customer_orders = '';

            this.setState({ LOADING:false, CustomerOrders:customer_orders, CustomerTax:result.tax, CustomerCess:result.cess });
        });
    }

    componentDidMount() {
        let mount = $("div").hasClass("modal-backdrop");
        if(mount===true)
        {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
            //window.location.reload(true);
        }

        this.GetCustomerOrderDetails();
    }

    render() { 

        //localStorage.removeItem("userData");
        //localStorage.removeItem("cartData");
        //localStorage.removeItem("delCity");
        //localStorage.removeItem("delArea");
        //console.log(localStorage.getItem('userData'));

        let logged = 'N';
        if(localStorage.getItem('userData'))
            logged = 'Y';

        if (logged==='N')
        {
            if(localStorage.getItem('cartData'))
                return (<Redirect to={'/cart'}/>)
            else
                return (<Redirect to={'/home'}/>)
        }
        
    	let AppHeaderClass = 'nkis-header appHeader no-shadow';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = '/orders';
    	let AppHeaderTitle = 'Order Summary';
    	let AppHeaderIMAGE = '';
    	let AppHeaderRight = 'showLINK';
    	let AppHeaderRightLink = '';

        return (
            <div>
                {this.state.LOADING && <Loader />}
                <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderIMAGE={AppHeaderIMAGE} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
                <div id="appCapsule">
                    <div className="section full mt-2 pb-4">
                        <div className="section inset pt-0 pb-1">
                            <div className="row">
                                <div className="col-12">
                                    {this.state.CustomerOrders.length>0 && this.state.CustomerOrders.map((post,i)=>{
                                        return (
                                            <div className="card p-2" key={i}>
                                                <div className="content-header p-0 font-size-13 text-dgrey font-weg-500">Delivery Address:</div>
                                                <div className="section-title w-100  d-flex justify-content-between pt-0 pb-0 pr-0 pl-0">
                                                    <p className="mb-0 pt-05 w-70 text-mgrey font-size-12">{post.ADDRESS}</p>
                                                    <span>
                                                        <span className={`btn text-upper newshadowed font-size-9 square btn-${post.STATUS_CSS}2 btn-sm btn-block`}>{post.STATUS}</span>
                                                        <p className="font-size-12 pt-1 text-center">{post.DATE2}</p>
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="cart-list-items">
                            <ul className="listview image-listview">
                                <li className="cart-list-head noafter">
                                    <div className="item">
                                        <div className="font-15 mb-0 text-left text-grey4 ft-wt-400 pr-1 pl-0 pt-0 pb-0 text-upper text-grey4 prod-name">Product </div>
                                        <div className="in prod-qty-total">
                                            <div className="item-name font-12 pr-1">
                                                <div className="add-btn add-btn-cart tp-neg-10 add-btn-inc mt-0 text-center font-15 text-grey4 text-upper ft-wt-400"> Qty </div>
                                            </div>
                                            <span className="badge bg-transparent badge-light mt-0 font-15 text-grey4 text-upper ft-wt-400">Total</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <ul className="listview image-listview">
                                { this.state.CustomerOrders.length>0 && <OrdersItemList orders={this.state.CustomerOrders} /> }
                                { this.state.CustomerOrders.length>0 && <OrdersTotalBreakUp orders={this.state.CustomerOrders} /> }
                            </ul>
                            
                        </div>
                        { this.state.CustomerOrders.length>0 && this.state.CustomerOrders.map((post,i)=>{
                            return(
                                <div>
                                    <div className="flex-disp1">
                                        <div className="section-title disp-block w-50 font-14 mb-05 ft-wt-500 text-upper mt-1 pr-2 pl-2 justify">Delivery Type:<br/><span className="flex-disp1 tick font-13"><ion-icon name="checkmark"></ion-icon> {post.TYPE_DETAIL} </span></div>
                                        {this.state.OrderPaymentMode!=='' && <div className="section-title disp-block w-50 font-14 mb-05 ft-wt-500 text-upper mt-1 pr-2 pl-2">Payment Mode:<br/><span className="flex-disp1 tick font-13"> <ion-icon name="checkmark"></ion-icon> {post.PAYMODE_DETAIL}</span> </div>}
                                    </div>
                                    {post.REFNO!=='' && 
                                        <div className="section-title justify font-14 mb-05 ft-wt-500 text-upper mt-1 pr-2 pl-2">Reference No:&nbsp; 
                                            {post.REFNO}
                                        </div>
                                    }
                                </div>
                            );
                        })
                        }

                        { this.state.CustomerOrders.length>0 && this.state.CustomerOrders.map((post,i)=>{
                            if(post.STATUS2!=='D')
                            {
                                return (
                                    <div className="form-button-group1 pr-1 pl-1" key={i}>
                                        <div className="col-12">
                                            <div className="text-upper">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <Link to={`/ordertrack/${post.ID}`} className="mt-1 btn newshadowed font-size-14 btn-danger btn-ssm btn-block br-4">Track Order</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }

                            if(post.STATUS2==='D')
                            {
                                return (
                                    <div className="form-button-group1 pr-2 pl-2" key={i}>
                                        <div className="col-12">
                                            <div className="text-upper">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <Link to={`/reorder/${post.ID}`} className="btn newshadowed font-size-14 square btn-danger btn-ssm btn-block">Re-Order</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        })
                        }
                    </div>
                </div>
            </div>
        );
    }
}
  
export default CustomerOrdersDetails;