import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
//import AppHeader from './AppHeader';
//import Loader from './Loader';

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        	LOADING: true,
        	Logged: false,
        }
    }

    componentDidMount() {
    	this.setState({
    		LOADING: true,
    	});
    	localStorage.clear();
    }

    render() {

		return (<Redirect to={'/home'}/>)

        /*let AppHeaderClass = 'nkis-header appHeader no-shadow';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = '/home';
    	let AppHeaderTitle = 'NKIS';
    	let AppHeaderIMAGE = '';
    	let AppHeaderRight = '';
    	let AppHeaderRightLink = '';

    	return(
    	    <div className="bg-white">
	    	    {this.state.LOADING && <Loader />}
	    	    <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderIMAGE={AppHeaderIMAGE} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
	    	</div>
    	)*/
    }
}
export default Index;