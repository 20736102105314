import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import Loader from './Loader';
import AppHeader from './AppHeader';
import BottomMenu from './BottomMenu';

import $ from "jquery";

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            LOCATION: this.props.location.pathname,
            LOADING: false, 
            userNAME: '',
            activeORDERS: 0,
            FAVOURITES: 0,
            activeADDRESSES:0,
            LoggedOut: false,
            customerid: localStorage.getItem('userData'),
        };
    }

    GetCustomerProfileDetails()
    {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'PROFILE', customer: this.state.customerid})
        };
        
        fetch(global.config.bURL+"profile_api.php", requestOptions)
        .then(function (response) {
            return response.json();
        })
        .then((result)=> {
            
            localStorage.setItem('nkis_favourites', result.FAVOURITES);

            this.setState({ 
                LOADING:false,
                userNAME:result.NAME,
                activeORDERS:result.ORDERS,
                FAVOURITES:result.FAVOURITES,
                activeADDRESSES:result.ADDRESSES
            });
        });
    }

    handleClickModalLogOut = () => {
        window.$('#ModalLogout').modal('show');
    }

    handleClickLogOut = () => {
        localStorage.removeItem('userData');
        localStorage.removeItem('Verified');
        this.setState({ LoggedOut:true });
    }

    componentDidMount() {
        let mount = $("div").hasClass("modal-backdrop");
        if(mount===true)
        {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
            //window.location.reload(true);
        }

        this.GetCustomerProfileDetails();
    }
    
    render() { 

        if(localStorage.getItem('userData')==null)
        {
	        return (<Redirect to={'/home'}/>)
        }

        if(this.state.LoggedOut)
        {
	        return (<Redirect to={'/home'}/>)
        }

        let AppHeaderClass = 'nkis-header appHeader no-shadow bg-blue text-white';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = '/home';
    	let AppHeaderTitle = this.state.userNAME;
    	let AppHeaderIMAGE = '';
    	let AppHeaderRight = 'showLINK';
    	let AppHeaderRightLink = '';

        return (
            <div>
                {this.state.LOADING && <Loader />}
	    	    {this.state.userNAME!=='' && <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderIMAGE={AppHeaderIMAGE} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />}
                <div id="appCapsule">
                    <div className="login-form mt-1">
                        <div className="profile delivery-location section mt-0 mb-5 pt-0 pl-0 pr-0">
                            <ul className="user-edits listview link-listview">
                                <li>
                                    <Link to="/orders" className="text-grey6">
                                        Orders {this.state.activeORDERS>0 && <span className="badge badge-primary">{this.state.activeORDERS}</span>}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/favourites" className="text-grey6">
                                        Favorites {this.state.FAVOURITES>0 && <span className="badge badge-primary">{this.state.FAVOURITES}</span>}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/changepassword" className="text-grey6">
                                        Reset Password
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/addresses" className="text-grey6">
                                        Addresses {this.state.activeADDRESSES>0 && <span className="badge badge-primary">{this.state.activeADDRESSES}</span>}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className="text-grey6" onClick={()=>{this.handleClickModalLogOut()}}>
                                        Logout
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="modal fade dialogbox" id="ModalLogout" data-backdrop="static" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">&nbsp;</h5>
                                </div>
                                <div className="modal-body"> 
                                   You are about to logout. Continue? 
                                </div>
                                <div className="modal-footer">
                                    <div className="btn-inline"> 
                                        <button type="button" className="btn btn-text-success" data-dismiss="modal" onClick={this.handleClickLogOut}>Yes</button>
                                        <button type="button" className="btn btn-text-danger" data-dismiss="modal">No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BottomMenu LOCATION={this.state.LOCATION} />
            </div>
        );
    }
}
      
export default Profile;