import React from 'react';
import {Redirect} from 'react-router-dom';

class SubmitOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            LOADING: true,
            orderSubmitted: 'N',
            customerid: localStorage.getItem('userData'),
            address_id: localStorage.getItem('addressID'),
            delTYPE: localStorage.getItem('deliveryTYPE'),
            paymode_id: localStorage.getItem('paymodeID'),
            refNO: localStorage.getItem('refNO'),
            orderID: '',
            payTYPE: ''
        };
    }

    GetCustomerOrder(itemLIST) {
        var subsciber_id = localStorage.getItem('WEBPUSHR_ID');
        var that = this;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'SUBMIT_ORDER', subsciber_id:subsciber_id, itemLIST:itemLIST, delTYPE:this.state.delTYPE, paymode_id:this.state.paymode_id, refNO:this.state.refNO, customer: this.state.customerid, address_id: this.state.address_id})
        };

        fetch(global.config.bURL+"submitorder_api.php", requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {
            //console.log(result);
            
            let order_submitted = result.order_submitted;
            if(order_submitted==='' || order_submitted===undefined)
                order_submitted = '';
            
            let payTYPE = result.payTYPE;


            if(order_submitted==='Y')
            {
                let paymode_id = localStorage.getItem('paymodeID');
                if(paymode_id!=='1')
                    localStorage.removeItem('cartData');
            }

            that.setState({ orderSubmitted:order_submitted, orderID:result.order_id, payTYPE:payTYPE  });
        });
    }

    componentDidMount() {
        let itemLIST = '';
        let cart = localStorage.getItem('cartData');
        let cartData = JSON.parse(cart);
        if(cart !== null && cart!=='{}')
        {
            Object.keys(cartData).forEach(function(key) {
                let itemCOUNT = cartData[key];
                let item = key+'~~**~~'+itemCOUNT;
                itemLIST += item+',';
            });
        }
        
        if(itemLIST!=='')
        {
            itemLIST = itemLIST.substring(0, itemLIST.length-1);
            this.GetCustomerOrder(itemLIST);
        }
    }
    
    render() { 

        //console.log(localStorage.getItem('delCity'));
        //console.log(localStorage.getItem('delArea'));

        if(this.state.orderSubmitted==='Y')
        {
            let paymode_id = localStorage.getItem('paymodeID');
            if(paymode_id=== null || paymode_id ==='{}')
            {   
                localStorage.setItem('paymodeID', 'P');
                let paymode_id = localStorage.getItem('paymodeID');
            }
            
            if(paymode_id==='1')
            {
                return (<Redirect to={`/pay/${this.state.orderID}`} />)
            }
            else
            {
                return (<Redirect to={`/ordertrack/${this.state.orderID}/Y`} />)
            }
        }
        else
        {
            let cartEMPTY = 'Y';
            let cart = localStorage.getItem('cartData');
            let cartData = JSON.parse(cart);
            if(cart !== null && cart!=='{}')
            {
                cartEMPTY = 'N';
            }

            if(cartEMPTY==='Y')
            {
                return (<Redirect to='/home' />)
            }
        }

        return (
            <div>
                {/*this.state.LOADING && <Loader />*/}
                <div id="appCapsule">
                    <div className="section pt-6" style={{textAlign: 'center'}}>
                        <div className="spinner-border text-danger" role="status"> </div>
                        <h4 className="size-19">Please Wait</h4>
                        <h4 className="size-19">We are processing your order, do not click back or reload. This process can take sometime</h4>
                    </div>
                </div>
            </div>
        );
    }
}
      
export default SubmitOrder;