import React from 'react';
import {Link } from 'react-router-dom';

class BottomMenu extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          currentLocation: props.LOCATION
      };
    }  

    render() { 
      let cartItemCount = 0;
      let cart = localStorage.getItem('cartData');
      let cartData = JSON.parse(cart);
      if(cart !== null && cart!=='{}')
      {
          Object.keys(cartData).forEach(function(key) {
              let itemCOUNT = cartData[key];
              cartItemCount = parseFloat(cartItemCount) + parseFloat(itemCOUNT);
          });    
      }

      let homeCSS = this.state.currentLocation==='/home'?' active':'';
      let listingCSS = this.state.currentLocation==='/categories'?' active':'';
      if(this.state.currentLocation.search('category')==='1')
        listingCSS = ' active';
      let cartCSS = this.state.currentLocation==='/cart'?' active':'';
      let searchCSS = this.state.currentLocation==='/search'?' active':'';
      let brandCSS = this.state.currentLocation==='/brands'?' active':'';

      return (
        <div className="appBottomMenu nkis-footer">
          <Link to="/categories" className={`item${listingCSS}`}>
            <div className="col">
              <ion-icon name="list-outline"></ion-icon>
              <strong>Categories</strong>
            </div>
          </Link>
          <Link to="/search" className={`item${searchCSS}`}>
            <div className="col">
              <ion-icon name="search-outline"></ion-icon>
              <strong>Search</strong>
            </div>
          </Link>
          <Link to="/home" className={`item${homeCSS}`}>
            <div className="col">
              <ion-icon name="home-outline"></ion-icon>
              <strong>Home</strong>
            </div>
          </Link>
          <Link to="/brands" className={`item${brandCSS}`}>
            <div className="col">
              <ion-icon name="pricetags-outline"></ion-icon>
              <strong>Brands</strong>
            </div>
          </Link>
          <Link to="/cart" className={`item${cartCSS}`}>
            <div className="col">
              <ion-icon name="cart-outline"></ion-icon>
              {cartItemCount!==0 && <span className="badge badge-danger">{cartItemCount}</span>}
              <strong>cart</strong>
            </div>
          </Link>
        </div>
      );
    }
}
  
export default BottomMenu;