import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import Loader from './Loader';
import AppHeader from './AppHeader';

import $ from "jquery";

class AddAddress2 extends React.Component {
    constructor(props) {
        //console.log(props);
        super(props);
        this.state = {
            LOADING: true,
            addressAdded: false, 
            Notifications: false,
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let err = 0;
        if ($('#frmAddAddress')[0].checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            err++;
        }
        $('#frmAddAddress')[0].classList.add('was-validated');

        if(err===0)
        {
            $('#submitLoading').css('display','block');
            
            const method = "POST";
            const body = new FormData(this.form);
            //console.log(body);
            var that = this;
            fetch(global.config.bURL+'address_api.php', { method, body })
            .then(function (response) {
                //console.log(response);
                return response.json();
            })
            .then(function (result) {

                //console.log(result);
                //alert(result);
                let notify = '';
                if(result.err!=='' && result.err!==undefined)
                    notify = result.err;

                let addressAdded = false;
                let address_id = result.address_id;
                if(address_id!=='' && address_id!==undefined)
                {
                    localStorage.setItem('addressID', address_id);
                    addressAdded = true;
                }

                that.setState({ Notifications: notify, addressAdded:addressAdded });
                $('#submitLoading').css('display','none');
                if(notify!=='')
                    window.$('#ModalAlert').modal('show');
          });
        }
    }

    componentDidMount() {
        let mount = $("div").hasClass("modal-backdrop");
        if(mount===true)
        {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
            //window.location.reload(true);
        }

        this.setState({ LOADING:false });
    }

    render() { 

        //localStorage.removeItem("userData");
        //localStorage.removeItem("cartData");
        //localStorage.removeItem("delCity");
        //localStorage.removeItem("delArea");
        //console.log(localStorage.getItem('userData'));

        let logged = 'N';
        if(localStorage.getItem('userData'))
            logged = 'Y';

        if (logged==='N')
        {
            if(localStorage.getItem('cartData'))
                return (<Redirect to={'/cart'}/>)
            else
                return (<Redirect to={'/home'}/>)
        }

        if(this.state.addressAdded)
        {
            return (<Redirect to={'/cart'}/>)
        }

        let AppHeaderClass = 'nkis-header appHeader no-shadow';
        let AppHeaderLeft = 'showLINK';
        let AppHeaderLeftLink = '/changeaddress';
        let AppHeaderTitle = 'Add New Address';
        let AppHeaderIMAGE = '';
        let AppHeaderRight = 'showLINK';
        let AppHeaderRightLink = '';

        return (
            <div>
                {this.state.LOADING && <Loader />}
                <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderIMAGE={AppHeaderIMAGE} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
                <div id="appCapsule">
                    <div className="login-form mt-1">
                        <div className="section mt-0 mb-5 pt-3 pl-2 pr-2">
                            <form id="frmAddAddress" ref={el => (this.form = el)} onSubmit={this.handleSubmit} className="needs-validation" noValidate>
                                <input type="hidden" value="ADD_ADDRESS" className="form-control" name="mode" id="mode" />
                                <input type="hidden" value={localStorage.getItem('userData')} className="form-control" name="customer" id="customer" />
                                <div className="form-group boxed mb-1">
                                    <div className="input-wrapper">
                                        <input type="text" className="form-control font-14" id="txtlabel" name="txtlabel" required placeholder="Label: Work, Office, Home, Other..." autoComplete="off"/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div> 
                                        <div className="invalid-feedback">Please enter label.</div>  
                                    </div>
                                </div>
                                <div className="form-group boxed mb-1">
                                    <div className="input-wrapper">
                                        <input type="text" className="form-control font-14" name="txtmobile" id="txtmobile" required placeholder="Phone" autoComplete="off"/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div> 
                                        <div className="invalid-feedback">Please enter your mobile no.</div>
                                    </div>
                                </div>
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <input type="text" className="form-control font-14" name="txtaddress" id="txtaddress" required placeholder="Address Line 1" autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div> 
                                        <div className="invalid-feedback">Please enter your address line 1.</div> 
                                    </div>
                                </div>
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <input type="text" className="form-control font-14" name="txtaddress2" id="txtaddress2" required placeholder="Address Line 2" autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div> 
                                        <div className="invalid-feedback">Please enter your address line 2.</div> 
                                    </div>
                                </div>
                                <div className="form-group boxed mb-1">
                                    <div className="input-wrapper">
                                        <input type="text" className="form-control font-14" id="txtlandmark" name="txtlandmark" required placeholder="Landmark" autoComplete="off"/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div> 
                                        <div className="invalid-feedback">Please enter landmark.</div>  
                                    </div>
                                </div>
                                <div className="form-group boxed mb-1">
                                    <div className="divide-50l">
                                        <div className="input-wrapper">
                                            <input type="text" className="form-control font-14" name="txtcity" id="txtcity" required placeholder="City" autoComplete="off"/>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            <div className="valid-feedback">&nbsp;</div> 
                                            <div className="invalid-feedback">Please enter your city.</div>
                                        </div>
                                    </div>
                                    <div className="divide-50r">
                                        <div className="input-wrapper">
                                            <input type="text" className="form-control font-14" name="txtstate" id="txtstate" required placeholder="State" autoComplete="off"/>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            <div className="valid-feedback">&nbsp;</div> 
                                            <div className="invalid-feedback">Please enter your state.</div>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="form-group boxed mb-1">
                                    <div className="divide-50l">
                                        <div className="input-wrapper">
                                            <input type="text" className="form-control font-14" name="txtcountry" id="txtcountry" required placeholder="Country" autoComplete="off" defaultValue="India" readOnly />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            <div className="valid-feedback">&nbsp;</div> 
                                            <div className="invalid-feedback">Please enter your country.</div>
                                        </div>
                                    </div>
                                    <div className="divide-50r">
                                        <div className="input-wrapper">
                                            <input type="text" className="form-control font-14" name="txtpincode" id="txtpincode" required placeholder="PinCode" autoComplete="off"/>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            <div className="valid-feedback">&nbsp;</div> 
                                            <div className="invalid-feedback">Please enter your pincode.</div>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="form-button-group">
                                    <button type="submit" className="text-upper btn btn-danger square btn-md lt-space1 btn-block skip">
                                        <span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{display: 'none'}} role="status" aria-hidden="true"></span>
                                        SAVE
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="modal fade dialogbox" id="ModalAlert" data-backdrop="static" tabIndex="-1" role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-icon text-danger">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </div>
                                    <div className="modal-body"> 
                                            {this.state.Notifications} 
                                    </div>
                                    <div className="modal-footer">
                                        <div className="btn-inline"> 
                                            <button type="button" className="btn" data-dismiss="modal">Ok</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddAddress2;