import React from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect } from 'react-router-dom';
import Loader from './Loader';
import AppHeader from './AppHeader';

import $ from "jquery";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        LOADING: true,
        OTP: false,
        CUSTOMER_DET: ''
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let err = 0;
    if ($('#frmForgotPassword')[0].checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        err++;
    }
    $('#frmForgotPassword')[0].classList.add('was-validated');

    if(err==0)
    {
        $('#submitLoading').css('display','block');

        const method = "POST";
        const body = new FormData(this.form);
        //console.log(body);
        var that = this;
        fetch(global.config.bURL+'forgot_password.php', { method, body })
        .then(function (response) {
            //console.log(response);
            return response.json();
        })
        .then(function (result) {

            //console.log(result);
            let notify = '';
            if(result.err!=='' && result.err!==undefined)
                notify = result.err;

            if(result.LINK_SENT==='Y')
            {
              notify = 'Please check your email id';
            }

			//console.log(otp_sent);
            that.setState({ Notifications: notify });
            $('#submitLoading').css('display','none');
            if(notify!=='' && result.LINK_SENT==='Y')
              window.$('#ModalAlert2').modal('show');
            else if(notify!=='')
              window.$('#ModalAlert').modal('show');
      });
    }
  }

componentDidMount() {
  let mount = $("div").hasClass("modal-backdrop");
  if(mount===true)
  {
      $('body').removeClass('modal-open');
      $(".modal-backdrop").remove();
  }

  this.setState({
    LOADING: false,
  });
}


  render() { 

    let AppHeaderClass = 'nkis-header appHeader no-shadow';
    let AppHeaderLeft = 'showLINK';
    let AppHeaderLeftLink = '/login';
    let AppHeaderTitle = 'Forgot Password';
    let AppHeaderIMAGE = '';
    let AppHeaderRight = '';
    let AppHeaderRightLink = '';

    return (
      <div>
        {this.state.LOADING && <Loader /> }
        <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderIMAGE={AppHeaderIMAGE} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
        <div id="appCapsule">
          <div className="login-form mt-1 pl-2 pr-2">
            <div className="section mt-6 mb-5 pt-3">
              <form name="frmForgotPassword" id="frmForgotPassword" ref={el => (this.form = el)} onSubmit={this.handleSubmit} className="needs-validation" noValidate>
                <input type="hidden" value="FORGOT_PASSWORD" className="form-control" name="mode" id="mode" required />
                <div className="form-group boxed mb-1">
                  <div className="input-wrapper">
                    <input type="email" className="form-control font-14" name="txtemail" id="txtemail" required placeholder="Email" autoComplete="off"/>
                    <i className="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                    <div className="valid-feedback">&nbsp;</div> 
                    <div className="invalid-feedback">Please enter your email id.</div>
                  </div>
                </div>
                <div className="form-group boxed">
                  <button type="submit" className="btn btn-primary btn-block btn-lg font-14 text-upper ft-wt-400 w-50 br-4 mb-05">
                    <span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{display: 'none'}} role="status" aria-hidden="true"></span>
                    Submit</button>
                </div>
              </form>
            </div>
          </div>
          <div className="modal fade dialogbox" id="ModalAlert" data-backdrop="static" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-icon text-danger">
                  <ion-icon name="close-circle"></ion-icon>
                </div>
                <div className="modal-body"> 
                  {this.state.Notifications} 
                </div>
                <div className="modal-footer">
                  <div className="btn-inline"> 
                    <button type="button" className="btn" data-dismiss="modal">Ok</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade dialogbox" id="ModalAlert2" data-backdrop="static" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-icon text-success">
                  <ion-icon name="checkmark-circle"></ion-icon>
                </div>
                <div className="modal-body"> 
                  {this.state.Notifications} 
                </div>
                <div className="modal-footer">
                  <div className="btn-inline"> 
                    <button type="button" className="btn" data-dismiss="modal">Ok</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
  
export default ForgotPassword;