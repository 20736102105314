import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class AppHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AppHeaderClass: props.AppHeaderClass,
            AppHeaderLeft: props.AppHeaderLeft,
            AppHeaderLeftLink: props.AppHeaderLeftLink,
            AppHeaderTitle: props.AppHeaderTitle,
            AppHeaderIMAGE: props.AppHeaderIMAGE,
            AppHeaderRight: props.AppHeaderRight,
            AppHeaderRightLink: props.AppHeaderRightLink,
            AppHeaderSearch: props.AppHeaderSearch || '',
            redirect: false,
            search_text: '',
        }
    }

    render() {

        let logged = 'N';
        if(localStorage.getItem('userData'))
            logged = 'Y';
        
        let AppHeaderFavourite = '';
        if(localStorage.getItem('nkis_favourites') !== null && localStorage.getItem('nkis_favourites')!=='{}')
            AppHeaderFavourite = localStorage.getItem('nkis_favourites')

        return (

            <div className={this.state.AppHeaderClass}>
                <div className="left">
                    {this.state.AppHeaderLeft === 'showLINK' && <Link to={process.env.PUBLIC_URL+this.state.AppHeaderLeftLink} className="headerButton back-arrow"><ion-icon name="arrow-back-outline"></ion-icon></Link>}
                    {this.state.AppHeaderLeft !== 'showLINK' && <Link to='#' className="headerButton" data-toggle="modal" data-target="#sidebarPanel"><ion-icon name="menu-outline"></ion-icon></Link>}
                </div>
                <div className="pageTitle text-upper ft-wt-400 w-75">
                    {this.state.AppHeaderIMAGE 
                		?<img src={this.state.AppHeaderIMAGE} alt={this.state.AppHeaderTitle} className="imaged w120" />
                		:this.state.AppHeaderTitle
                	}
                </div>
                <div className="right">
                    {logged==='Y' && AppHeaderFavourite!=='' && AppHeaderFavourite>0 &&
                        <Link to="/favourites" className="headerButton user-icon">
                            <ion-icon class="liked-cat like-filled-light" name="heart"></ion-icon>
                            <span className="badge badge-heart">{AppHeaderFavourite}</span>
                        </Link>
                    }
                    {this.state.AppHeaderRight === 'showLINK' &&
                        <Link to={logged==='Y'?'/profile':'/login'} className="headerButton user-icon">
                            <ion-icon name="person-outline"></ion-icon>
                        </Link>
                    }
                </div>
            </div>
        );
    }
}

export default AppHeader;