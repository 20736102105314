import React from 'react';
import {Link} from 'react-router-dom';
import Loader from './Loader';
import AppHeader from './AppHeader';
import BottomMenu from './BottomMenu';
import SidebarPanel from './SidebarPanel';
import {currencyFormat} from './Common.js';

import $ from "jquery";

class Cart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            LOCATION: this.props.location.pathname,
            LOADING: true,
            CART_ITEMS: [],
            PAYMENT_MODE:[],
            CartCount: 0,
            cartEMPTY: 'Y',
            CUSTOMER_DET: '',
            FAVOURITES: 0,
            customerid: localStorage.getItem('userData'),
            address_id: localStorage.getItem('addressID'),
            address_label: '',
            address_landmark:'',
            address_detail: '',
            address_mobile: '',
        };
    }

    GetCartItemList(itemLIST) 
    {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'CART_ITEM', itemLIST:itemLIST, customer: this.state.customerid, address_id: this.state.address_id})
        };

        fetch(global.config.bURL+"cart_items.php", requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {

            //console.log(result);

            let ITEMS = result.CART_LIST;
            let PAYMODE = result.PAYMENT_MODE;
            let FAVOURITES = result.FAVOURITES;
            var CUSTOMER_DET = 'Retail~~**~~Hi User';

            if(result.CUSTOMER!=='' && result.CUSTOMER!==undefined && result.CUSTOMER!=='undefined')
                CUSTOMER_DET = result.CUSTOMER;

            let address_id = 0;
            if(result.ADDRESS_ID!=='' && result.ADDRESS_ID!==undefined && result.ADDRESS_ID!=='undefined')
            {
                address_id = result.ADDRESS_ID;
                localStorage.setItem('addressID',address_id);
            }

            let address_label = '';
            if(result.ADDRESS_LABEL!=='' && result.ADDRESS_LABEL!==undefined && result.ADDRESS_LABEL!=='undefined')
                address_label = result.ADDRESS_LABEL;

            let address_landmark = '';
            if(result.ADDRESS_LANDMARK!=='' && result.ADDRESS_LANDMARK!==undefined && result.ADDRESS_LANDMARK!=='undefined')
                address_landmark = result.ADDRESS_LANDMARK;

            let address_detail = '';
            if(result.ADDRESS_DETAIL!=='' && result.ADDRESS_DETAIL!==undefined && result.ADDRESS_DETAIL!=='undefined')
                address_detail = result.ADDRESS_DETAIL;

            let address_mobile = '';
            if(result.ADDRESS_MOBILE!=='' && result.ADDRESS_MOBILE!==undefined && result.ADDRESS_MOBILE!=='undefined')
                address_mobile = result.ADDRESS_MOBILE;

            this.setState({ 
                CART_ITEMS:ITEMS,
                PAYMENT_MODE:PAYMODE,
                cartEMPTY:'N',
                FAVOURITES: FAVOURITES,
                CUSTOMER_DET: CUSTOMER_DET,
                LOADING: false,
                address_id: address_id,
                address_label: address_label,
                address_landmark: address_landmark,
                address_detail: address_detail,
                address_mobile: address_mobile,
            });
        });
    }

    handleClickIncreement = (id,cartCount)=>{
        let cartTotalCount = 0;
        cartCount = cartCount + 1;

        let cart = localStorage.getItem('cartData');
        var that = this;
        var cartData = JSON.parse(cart);
        Object.keys(cartData).forEach(function(key) {
            let cart_id = key;
            if(cart_id===id)
                cartTotalCount += cartData[key];

            if(cart_id===id)
                cartData[key] = cartCount;
        });
        cartTotalCount += 1;

        localStorage.setItem('cartData', JSON.stringify(cartData));
        this.setState({ CartCount:1});
    }

    handleClickDecreement = (id,cartCount)=>{
        
        cartCount = (cartCount!==0)?cartCount - 1:0;
        let cart = localStorage.getItem('cartData');
        var that = this;
        var cartData = JSON.parse(cart);
        Object.keys(cartData).forEach(function(key) {
            let cart_id = key;
            if(cart_id===id)
            {
                if(cartCount===0)
                    delete cartData[key];
                else
                    cartData[key] = cartCount;
            }
        });

        localStorage.setItem('cartData', JSON.stringify(cartData));
        cart = localStorage.getItem('cartData');
        if(cart === null || cart ==='{}')
        {
            localStorage.removeItem("cartData");
            this.setState({ cartEMPTY:'Y'});
        }
        else 
            this.setState({ cartEMPTY:'N'});
    }

    handleClickAddressMissing = () =>{
        window.$('#ModalAddressMissing').modal('show');
    }

    handleCartDeliveryType = () => {

        let delTYPE = '';
        $.each($("input[name='rddeliverytype']:checked"), function(){            
            delTYPE = $(this).val();
        });
        localStorage.setItem('deliveryTYPE', delTYPE);
        this.setState({ cartEMPTY:'N'});
    }

    handleCartPayModeType = () => {

        let paymode_id = '';
        $.each($("input[name='rdpaymode']:checked"), function(){            
            paymode_id = $(this).val();
        });
        localStorage.setItem('paymodeID', paymode_id);
        this.setState({ cartEMPTY:'N'});
    }

    handleClickPayModeMissing = () =>{
        window.$('#ModalPayModeMissing').modal('show');
    }
    
    handleClickDeliveryTypeMissing = () =>{
        window.$('#ModalDeliveryTypeMissing').modal('show');
    }

    componentDidMount() {
        let mount = $("div").hasClass("modal-backdrop");
        if(mount===true)
        {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
        }

        var that = this;
        let cart = localStorage.getItem('cartData');

        let itemLIST = '';
        if (cart !== null) {
            let cartData = JSON.parse(cart);
            //console.log(cartData)
            
            Object.keys(cartData).forEach(function(key) {
                //console.log(key);
                //console.log(cartData[key]);
                let id = key;
                itemLIST += id+',';
            });
        }

        if(itemLIST!=='')
        {
            itemLIST = itemLIST.substring(0, itemLIST.length-1);
            this.GetCartItemList(itemLIST);
        }
        else
        {
            this.GetCartItemList();
        }
    }

    render() { 

        let AppHeaderClass = 'nkis-header appHeader no-shadow';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = '/home';
    	let AppHeaderTitle = 'Cart';
    	let AppHeaderIMAGE = '';
    	let AppHeaderRight = 'showLINK';
    	let AppHeaderRightLink = '';

        //localStorage.removeItem('userData');

        let logged = 'N';
        if(localStorage.getItem('userData')) 
            logged = 'Y';

        let address_id = '';
        if(localStorage.getItem('addressID'))
            address_id = localStorage.getItem('addressID');

        let delTYPE = '';
        if(localStorage.getItem('deliveryTYPE'))
            delTYPE = localStorage.getItem('deliveryTYPE');

        let paymode_id = '';
        if(localStorage.getItem('paymodeID'))
            paymode_id = localStorage.getItem('paymodeID');
    
        let cart = localStorage.getItem('cartData');
        let cartData = JSON.parse(cart);
        
        if(cart !== null && cart!=='{}')
        {
            let LIST = this.state.CART_ITEMS;
            Object.keys(cartData).forEach(function(key) {
                let cart_id = key.split('~~**~~');
        
                if(LIST.length>0)
                {
                    LIST.map(id =>{
        
                        if(cart_id[0]==id.ITEMID)
                        {
                            if(id.STOCK==='N')
                            {
                                delete cartData[key];
                            }
                        }
                    });
                }
            });
        
            localStorage.setItem('cartData', JSON.stringify(cartData));
        }

        cart = localStorage.getItem('cartData');
        cartData = JSON.parse(cart);
        let cartItemCount = 0;
        let cartEMPTY = 'Y';
    
        if(cart !== null && cart!=='{}')
        {
            cartEMPTY = 'N';
            Object.keys(cartData).forEach(function(key) {
    
                let itemCOUNT = cartData[key];
                cartItemCount = cartItemCount + itemCOUNT;
            });
        }
        
        //if(cartEMPTY=='Y')
            //this.setState({ cartEMPTY:'Y'});

        let cartTOTAL = 0;
        let taxTOTAL = 0;
        let savingsTOTAL = 0;
    
        return (
            <div>
                {this.state.LOADING && <Loader />}
	    	    <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderIMAGE={AppHeaderIMAGE} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
                <div id="appCapsule">
                    {cartEMPTY==='Y' &&
                        <div className="section pt-6" style={{textAlign: 'center'}}>
                            <img src="/assets/img/empty-cart.png" alt="empty-cart" className="form-image imaged w200" />
                            <h4 className="size-19">Empty Cart</h4>
                        </div>
                    }
                    {cartEMPTY==='N' &&
                        <div>
                            <div className="section full mt-2">
                                <div className="cart-list-items">
                                    <ul className="listview image-listview">
                                        <li className="cart-list-head noafter">
                                            <div className="item">
                                                <div className="font-15 mb-0 ft-wt-400 pr-1 pl-0 pt-0 pb-0 text-upper text-grey4 prod-name">Product </div>
                                                <div className="in prod-qty-total">
                                                    <div className="item-name font-12 pr-1">
                                                        <div className="add-btn add-btn-cart tp-neg-10 add-btn-inc mt-0 text-center font-15 text-grey4 text-upper ft-wt-400"> Qty </div>
                                                    </div>
                                                    <span className="badge bg-transparent badge-light mt-0 font-15 text-grey4 text-upper ft-wt-400">Total</span>
                                                </div>
                                            </div>
                                        </li>
                                        {
                                            this.state.CART_ITEMS.map(item=>{
                                                let cartCount = 0; //this.state.cartCount;

                                                let currentITEM = item.ID;
                                                let cart = localStorage.getItem('cartData');
                                                if (cart !== null) {
                                                    let cartData = JSON.parse(cart);
                                                    Object.keys(cartData).forEach(function(key) {
                                                        let id = key;
                                                        if(id===currentITEM)  
                                                            cartCount += cartData[key];
                                                    });
                                                }

                                                let itemTOTAL = parseFloat(item.PRICE) * parseFloat(cartCount);
                                                cartTOTAL = parseFloat(cartTOTAL) + parseFloat(itemTOTAL);
                                                
                                                if(itemTOTAL>0)
                                                    itemTOTAL = currencyFormat(itemTOTAL);

                                                let itemTAXTOTAL = parseFloat(item.TAX) * parseFloat(cartCount);
                                                taxTOTAL = parseFloat(taxTOTAL) + parseFloat(itemTAXTOTAL);

                                                let itemSAVINGS = parseFloat(item.SAVINGS) * parseFloat(cartCount);
                                                savingsTOTAL = parseFloat(savingsTOTAL) + parseFloat(itemSAVINGS);

                                                if(cartCount>0)
                                                {
                                                    return (
                                                        <li key={item.ID}>
                                                            <div className="item"> 
                                                                <div class="prod-name">
                                                                    <img src={item.IMAGE} alt={item.ALT_TAG} className="image" />
                                                                </div>
                                                                <div className="in prod-qty-total">
                                                                    <div className="item-name font-12 pr-1">
                                                                        <div className="title-cut-sub text-grey4 font-13 ft-wt-500 pb-05">{item.NAME}</div>
                                                                        <div className="final-price text-grey font-15 pt-0 ft-wt-400 text-upper pr-0">&#8377; {item.PRICE}</div>
                                                                        <div className="add-btn add-btn-cart add-btn-inc mt-0">
                                                                            <input type="button" onClick={()=>{this.handleClickDecreement(item.ID,cartCount)}} value="-" className="qtyminus" />
                                                                            <input type="text" value={cartCount} readOnly className="qty" />
                                                                            <input type="button" onClick={()=>{this.handleClickIncreement(item.ID,cartCount)}}  value="+" className="qtyplus"/>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    <span className="badge badge-light mt-2 font-15 ft-wt-400">&#8377; {itemTOTAL}</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                }
                                            })
                                        }
                                        { taxTOTAL>0 &&
                                            <li className="cart-list-head">
                                                <div className="section-title w-100 font-13 text-upper d-flex justify-content-between pt-2 pb-2 pr-2 pl-2 text-grey4"> Inclusive Taxes Breakups
                                                    <span className="ft-wt-500">&#8377; {currencyFormat(taxTOTAL.toFixed(2))}</span>
                                                </div>
                                            </li>
                                        }
                                        { savingsTOTAL>0 &&
                                            <li className="cart-list-head">
                                                <div className="section-title w-100 font-13 text-upper d-flex justify-content-between pt-2 pb-2 pr-2 pl-2 text-grey4"> Total Savings 
                                                    <span className="ft-wt-500">&#8377; {currencyFormat(savingsTOTAL.toFixed(2))}</span>
                                                </div>
                                            </li>
                                        }
                                        <li className="ht-70 noafter">
                                            <div className="section-title w-100 text-upper d-flex justify-content-between pt-2 pb-2 pr-2 pl-2 ">Grand Total 
                                                <span className="font-weg-600">&#8377; {currencyFormat(cartTOTAL.toFixed(2))}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="wide-block pt-2 bg-transparent rad-select mb-0 pb-0">
                                <div className="custom-control custom-radio mb-1">
                                    <input onClick={()=>{this.handleCartDeliveryType()}} type="radio" id="deltype_D" name="rddeliverytype" className="custom-control-input" defaultValue='D' defaultChecked={delTYPE==='D'?'checked':''} />
                                    <label className="custom-control-label text-upper" htmlFor="deltype_D">Delivery</label>
                                </div>
                                <div className="custom-control custom-radio mb-1">
                                    <input onClick={()=>{this.handleCartDeliveryType()}} type="radio" id="deltype_P" name="rddeliverytype" className="custom-control-input" defaultValue='P' defaultChecked={delTYPE==='P'?'checked':''} />
                                    <label className="custom-control-label text-upper" htmlFor="deltype_P">Pickup</label>
                                </div>
                                <div className="clear"></div>
                            </div>
                            {this.state.PAYMENT_MODE.length>0 && this.state.PAYMENT_MODE.map(post => {
                                let checked = false;
                                if(paymode_id!=='')
                                {
                                    if(paymode_id===post.ID)
                                        checked = true;
                                }

                                let display = 'Y';
                                if(delTYPE==='D')
                                {
                                    if(post.TYPE==='P')
                                        display = 'N';
                                }
                                else if(delTYPE==='P')
                                {
                                    if(post.TYPE==='D')
                                        display = 'N';
                                }
                                else
                                {
                                    if(post.TYPE!=='B')
                                        display = 'N';
                                }
                                
                                if(display==='Y')
                                {
                                    return (
                                        <div className="wide-block pt-2 pb-2" key={post.ID}>
                                            <div className="sumup">
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" onClick={()=>{this.handleCartPayModeType()}} id={`rdpaymode_${post.ID}`} name="rdpaymode" value={post.ID} className="custom-control-input" defaultChecked={checked} />
                                                    <label className="custom-control-label" htmlFor={`rdpaymode_${post.ID}`}>
                                                        <h5 className="font-weg-400 mb-05">{post.TITLE}</h5>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>                 
                                    );
                                }
                            })}
                            <div className="section-title font-14 mb-05 ft-wt-500 text-upper mt-1 pr-2 pl-2">{this.state.address_label}&nbsp;
                                <Link to={address_id===''?'/addaddress2':'/changeaddress'}>
                                    <span className="badge bt-blue font-size-10 ht-20">{address_id===''?'Add':'Change'}</span>
                                </Link>
                            </div>
                            <div className="section full mt-1 pr-2 pl-2 mb-100">
                                {this.state.address_detail && <p className="text-left font-13 mb-0 pb-1">{this.state.address_detail}</p>}
                                {this.state.address_mobile && <p className="text-left font-13 mb-0">{this.state.address_mobile} </p>}
                            </div>
                            <div className="form-button-group2 bg-green">
                                <div className="col-12">
                                    <div className="section-title w-100  d-flex justify-content-between">
                                        <div className="font-12 pt-1 pb-1 pr-0 pl-0 medium text-left text-white ft-wt-300"> Total<br />
                                            <span className="font-16 lh-24">&#8377; {currencyFormat(cartTOTAL.toFixed(2))}</span>
                                        </div>
                                        {logged==='Y' && address_id!=='' && delTYPE!=='' && paymode_id!=='' &&
                                            <Link to='/checkout'>
                                                <div className="text-white ft-wt-300 text-upper view-cart">Check Out
                                                    <ion-icon name="arrow-forward-outline"></ion-icon>
                                                </div>
                                            </Link>
                                        }
                                        {logged==='Y' && address_id!=='' && delTYPE!=='' && paymode_id==='' &&
                                            <Link to='#' onClick={this.handleClickPayModeMissing}>
                                                <div className="text-white ft-wt-300 text-upper view-cart">Check Out
                                                    <ion-icon name="arrow-forward-outline"></ion-icon>
                                                </div>
                                            </Link>
                                        }
                                        {logged==='Y' && address_id!=='' && delTYPE==='' &&
                                            <Link to='#' onClick={this.handleClickDeliveryTypeMissing}>
                                                <div className="text-white ft-wt-300 text-upper view-cart">Check Out
                                                    <ion-icon name="arrow-forward-outline"></ion-icon>
                                                </div>
                                            </Link>
                                        }
                                        {logged==='Y' && address_id==='' &&
                                            <Link to='#' onClick={this.handleClickAddressMissing}>
                                                <div className="text-white ft-wt-300 text-upper view-cart">Check Out
                                                    <ion-icon name="arrow-forward-outline"></ion-icon>
                                                </div>
                                            </Link>
                                        }
                                        {logged!=='Y' &&
                                            <Link to='/login'>
                                                <div className="text-white ft-wt-300 text-upper view-cart">Check Out
                                                    <ion-icon name="arrow-forward-outline"></ion-icon>
                                                </div>
                                            </Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="modal fade dialogbox" id="ModalAddressMissing" data-backdrop="static" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-icon text-danger">
                                <ion-icon name="close-circle"></ion-icon>
                            </div>
                            <div className="modal-body">
                                <p className="if-no-add">Please add delivery address</p> 
                            </div>
                            <div className="modal-footer">
                                <div className="btn-inline"> 
                                    <button type="button" className="btn" data-dismiss="modal">Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade dialogbox" id="ModalDeliveryTypeMissing" data-backdrop="static" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-icon text-danger">
                                <ion-icon name="close-circle"></ion-icon>
                            </div>
                            <div className="modal-body">
                                <p className="if-no-add">Please choose delivery type</p> 
                            </div>
                            <div className="modal-footer">
                                <div className="btn-inline"> 
                                    <button type="button" className="btn" data-dismiss="modal">Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade dialogbox" id="ModalPayModeMissing" data-backdrop="static" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-icon text-danger">
                                <ion-icon name="close-circle"></ion-icon>
                            </div>
                            <div className="modal-body">
                                <p className="if-no-add">Please choose payment mode</p> 
                            </div>
                            <div className="modal-footer">
                                <div className="btn-inline"> 
                                    <button type="button" className="btn" data-dismiss="modal">Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BottomMenu LOCATION={this.state.LOCATION} />
                {this.state.CUSTOMER_DET && <SidebarPanel CUSTOMER_DET={this.state.CUSTOMER_DET}/>}
            </div>
        );
    }
}
      
export default Cart;