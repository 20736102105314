module.exports = global.config = {
    bURL : "https://nkiscomputers.com/2021/api/",
    registerURL : "",
    headerImage: '/assets/images/two-heavens2.png',
    defaultImage: "/assets/img/no-img.jpg",
    tlcImage: "/assets/img/icon/icons-white/96x96.png",
    tlcImage2: "/assets/img/icon/icons-white/tlc_img2.png",
    defaultProfile: "/assets/img/user.jpg",
    defaultWorkoutImage: "/assets/img/workout1.jpg",
    RZ_TEST_KEY: 'rzp_test_173Rfe4mnnanVl',
    RZ_LIVE_KEY: 'rzp_live_tQNtNfmMoFwADY',
};