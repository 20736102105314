import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import Loader from './Loader';
import AppHeader from './AppHeader';

import $ from "jquery";

class ChangeAddress extends React.Component {
    constructor(props) {
        //console.log(props);
        super(props);
        this.state = {
            LOADING: true,
            addressChanged: false, 
            CustomerAddresses: [],
            customerid: localStorage.getItem('userData'),
            address_id: localStorage.getItem('addressID'),
        };
    }

    GetCustomerAddressList() {
        var that = this;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'ADDRESS_LIST', customer: this.state.customerid, address_id: this.state.address_id})
        };

        fetch(global.config.bURL+"address_list.php", requestOptions)
        .then(function (response) {
            return response.json();
        }).then(function (result) {
            //console.log(result);
            
            let customer_addresses = result.ADDRESS_LIST;
            if(customer_addresses==='' || customer_addresses===undefined)
                customer_addresses = '';

            that.setState({ LOADING:false, CustomerAddresses:customer_addresses });
        });
    }

    handleAddressChanged = () => {

        let address_id = '';
        $.each($("input[name='address']:checked"), function(){            
            address_id = $(this).val();
        });
        localStorage.setItem('addressID', address_id);

        this.setState({ addressChanged:true });
    }

    componentDidMount() {
        let mount = $("div").hasClass("modal-backdrop");
        if(mount===true)
        {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
            //window.location.reload(true);
        }

        this.GetCustomerAddressList();
    }

    render() { 

        let logged = 'N';
        if(localStorage.getItem('userData'))
            logged = 'Y';

        if (logged==='N')
        {
            if(localStorage.getItem('cartData'))
                return (<Redirect to={'/cart'}/>)
            else
                return (<Redirect to={'/home'}/>)
        }
        
        if(this.state.addressChanged)
        {
            return (<Redirect to={'/cart'}/>)
        }

        let address_id = '';
        if(localStorage.getItem('addressID')!=null)
            address_id = localStorage.getItem('addressID');

        let AppHeaderClass = 'nkis-header appHeader no-shadow';
        let AppHeaderLeft = 'showLINK';
        let AppHeaderLeftLink = '/cart';
        let AppHeaderTitle = 'Delivery Locations';
        let AppHeaderIMAGE = '';
        let AppHeaderRight = 'showLINK';
        let AppHeaderRightLink = '';

        return (
            <div>
                {this.state.LOADING && <Loader />}
                <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderIMAGE={AppHeaderIMAGE} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
                <div id="appCapsule">
                    <div className="login-form mt-1">
                        <div className="section-title font-size-17 mb-0 text-sdgrey pr-2 pl-2 pt-1 pb-1 text-upper text-grey4">Select Address </div>
                        <div className="delivery-location section mt-0 mb-5 pt-0 pl-0 pr-0">
                            {this.state.CustomerAddresses.length>0 && this.state.CustomerAddresses.map(post => {

                                let checked = false;
                                if(address_id!=='')
                                {
                                    if(address_id===post.ID)
                                        checked = true;
                                }
                                else
                                {
                                    if(post.DEFAULT==='Y')
                                        checked = true;
                                }

                                return (
                                    <div className="wide-block pt-2 pb-2" key={post.ID}>
                                        <div className="sumup">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" id={`address_${post.ID}`} name="address" value={post.ID} className="custom-control-input" defaultChecked={checked} />
                                                <label className="custom-control-label" htmlFor={`address_${post.ID}`}>
                                                    <div class="location-add">
                                                    {/*<h5 className="ft-wt-400 mb-05">{post.CIRCLE}
                                                        <span className="badge chip-warning chip-outline ht-17 font-size-10  font-weg-500 text-upper ltsp">{post.LABEL}</span>
                                                    </h5>*/}
                                                    <p className="font-14 ft-wt-400 pb-05 mb-1">{post.ADDRESS}</p>
                                                    {post.MOBILE_NO && <div className="stateline pin">
                                                        <span className="text-lgrey font-12 mb-05 mr-1">{post.MOBILE_NO}</span>
                                                    </div>}
                                                    </div>
                                                    <span className="badge location-status chip-warning chip-outline ht-17 font-size-10  font-weg-500 text-upper ltsp">{post.LABEL}</span>
                                                    
                                                </label>
                                            </div>
                                        </div>
                                    </div>                 
                                );
                            }) }
                            {this.state.CustomerAddresses.length===0 &&
                                <div className="form-button-group1 pr-2 pl-2">
                                    <div className="col-12">
                                        <div className="text-upper">
                                            <div className="row">
                                                <div className="col-12">
                                                    <Link to="/addaddress2" className="btn newshadowed font-size-14 square btn-danger btn-ssm btn-block">Add New Address</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.CustomerAddresses.length>0 &&
                                <div className="carousel-button-footer">
                                    <div className="row">
                                        <div className="col-6">
                                            <Link to="/addaddress2" className="btn newshadowed br-4 text-dgrey text-upper font-11  btn-outline-secondary btn-ssm btn-block goBack">Add New Address</Link>
                                        </div>
                                        <div className="col-6">
                                            <button onClick={()=>{this.handleAddressChanged()}} type="button" className="btn btn-red btn-block btn-ssm font-14 text-upper ft-wt-400 w-100 br-4 mb-05">Next</button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
  
export default ChangeAddress;