import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import Loader from './Loader';
import AppHeader from './AppHeader';
import BottomMenu from './BottomMenu';
import SidebarPanel from './SidebarPanel';

import $ from "jquery";

class CustomerOrderTrack extends React.Component {
    constructor(props) {
        //console.log(props);
        super(props);
        this.state = {
            LOCATION: this.props.location.pathname,
            LOADING: true, 
            id: props.match.params.id,
            showModal: props.match.params.showModal || 'N',
            orderStatus: '',
            orderTimeline: [],
            CUSTOMER_DET: '',
            FAVOURITES: 0,
            customerid: localStorage.getItem('userData'),
        };
    }

    GetCustomerOrderTrack() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'TRACK_ORDER', id:this.state.id, customer: this.state.customerid})
        };

        fetch(global.config.bURL+"order_track.php", requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {
            //console.log(result);
            
            var CUSTOMER_DET = 'Retail~~**~~Hi User';
            if(result.CUSTOMER!=='' && result.CUSTOMER!==undefined && result.CUSTOMER!=='undefined')
                CUSTOMER_DET = result.CUSTOMER;
            
            this.setState({ LOADING:false, orderStatus:result.status, orderTimeline:result.timeline, CUSTOMER_DET:CUSTOMER_DET });
            if(this.state.showModal==='Y')
                window.$('#OrderPlacedModal').modal('show');
        });
    }

    componentDidMount() {
        let mount = $("div").hasClass("modal-backdrop");
        if(mount===true)
        {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
            //window.location.reload(true);
        }

        this.GetCustomerOrderTrack();

        setTimeout(() => {
            //window.location.href = "/ordertrack/"+this.state.id;
            this.GetCustomerOrderTrack();
        }, 60000)
    }

    render() {

        let AppHeaderClass = 'nkis-header appHeader no-shadow';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = '/orders';
    	let AppHeaderTitle = 'Order Status';
    	let AppHeaderIMAGE = '';
    	let AppHeaderRight = 'showLINK';
    	let AppHeaderRightLink = '';

        //console.log(this.props);
        return (
            <div>
                {this.state.LOADING && <Loader /> }
                <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderIMAGE={AppHeaderIMAGE} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
                <div id="appCapsule">
                    <div className="section full mt-2">
                        <div className="section-title">Order ID: {this.state.id}</div>
                        {this.state.orderStatus && <div className="content-header mb-05">STATUS : {this.state.orderStatus}</div>}
                        <div className="wide-block">
                            <div className="timeline">
                                {this.state.orderTimeline && 
                                    this.state.orderTimeline.map((post, i)=>{
                                        return (
                                        <div className="item" key={i}>
                                            <div className={`dot bg-${post.css}`}></div>
                                            <div className="content">
                                                <h4 className="title">{post.action}</h4>
                                                <div className="text">{post.date}</div>
                                            </div>
                                        </div>
                                        )
                                    })
                                 }
                            </div>
                        </div>
                    </div>
                    <div className="section mt-1 mb-5"> <br />
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                                <Link to={`/orderdetails/${this.state.id}`} className="btn br-4 btn-danger btn-block">ORDER SUMMARY</Link>
                            </div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                </div>
                <BottomMenu LOCATION={this.state.LOCATION} />
                {this.state.CUSTOMER_DET && <SidebarPanel CUSTOMER_DET={this.state.CUSTOMER_DET}/>}
                {this.state.showModal==='Y' && 
                    <div className="modal fade dialogbox" id="OrderPlacedModal" data-backdrop="static" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-icon text-success">
                                    <ion-icon name="checkmark-circle"></ion-icon>
                                </div>
                                <div className="modal-header">
                                    <h5 className="modal-title">Thank you for placing an order</h5>
                                </div>
                                <div className="modal-body">
                                    Your order <strong>#{this.state.id}</strong> will be processed soon
                                </div>
                                <div className="modal-footer">
                                    <div className="btn-inline">
                                        <Link to={`/ordertrack/${this.state.id}`} className="btn" data-dismiss="modal">Order Status</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
  
export default CustomerOrderTrack;