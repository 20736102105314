import React from 'react';
import {Link} from 'react-router-dom';
import Loader from './Loader';
import AppHeader from './AppHeader';
import BottomMenu from './BottomMenu';
import SidebarPanel from './SidebarPanel';

import $ from "jquery";

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            LOCATION: this.props.location.pathname,
            LOADING: true, 
            CATEGORY: '',
            BRAND:'',
            PRODUCTS: [],
            category_id: props.match.params.id,
            CATEGORYNAME: '',
            CUSTOMER_DET: '',
            page: 1,
            displayLoadMore: 'N',
            orderby: '',
            FAVOURITES: 0,
            customerid: localStorage.getItem('userData'),
        };
    }

    GetCategoryItemList() 
    {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'ITEMS_LIST', category_id:this.state.category_id, orderby:this.state.orderby, customer: this.state.customerid})
        };

        fetch(global.config.bURL+"item_list.php", requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {

            //console.log(result);

            let CATEGORY = result.CATEGORY;
            let BRAND = result.BRAND;
            let ITEMS = [];
            ITEMS = result.ITEMS;
            let FAVOURITES = result.FAVOURITES;
            var CUSTOMER_DET = 'Retail~~**~~Hi User';
            if(result.CUSTOMER!=='' && result.CUSTOMER!==undefined && result.CUSTOMER!=='undefined')
                CUSTOMER_DET = result.CUSTOMER;

            localStorage.setItem('nkis_favourites', FAVOURITES);
                
            this.setState({ 
                PRODUCTS:ITEMS,
                page: result.page,
                displayLoadMore: result.LOADMORE,
                FAVOURITES: FAVOURITES,
                CATEGORY: CATEGORY,
                BRAND: BRAND,
                CUSTOMER_DET: CUSTOMER_DET,
                LOADING: false, 
            });
        });
    }

    fetchSecond(page) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'ITEMS_LIST', category_id:this.state.category_id, orderby:this.state.orderby, page:page, customer: this.state.customerid})
        };

        fetch(global.config.bURL+"item_list.php", requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {

            let CATEGORY = result.CATEGORY;
            let BRAND = result.BRAND;
            let ITEMS = result.ITEMS;
            let FAVOURITES = result.FAVOURITES;
            var CUSTOMER_DET = 'Retail~~**~~Hi User';
            if(result.CUSTOMER!=='' && result.CUSTOMER!==undefined && result.CUSTOMER!=='undefined')
                CUSTOMER_DET = result.CUSTOMER;

            this.setState({ 
                PRODUCTS:this.state.PRODUCTS.concat(result.ITEMS),
                page: result.page,
                displayLoadMore: result.LOADMORE,
                FAVOURITES: FAVOURITES,
                CATEGORY: CATEGORY,
                BRAND: BRAND,
                CUSTOMER_DET: CUSTOMER_DET,
                LOADING: false, 
            });
        });
    }

    handleItemOrderDisplay = (rdorder) => {
        //console.log(rdorder);
        this.setState({ LOADING: true, orderby:rdorder });
        this.GetCategoryItemList();
    }

    componentDidMount() {
        let mount = $("div").hasClass("modal-backdrop");
        if(mount===true)
        {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
        }

        this.GetCategoryItemList();
    }

    loadMore = () => {
        this.fetchSecond(this.state.page);
    };
  
    render() { 

        let AppHeaderClass = 'nkis-header appHeader no-shadow';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = '/categories';
    	let AppHeaderTitle = this.state.CATEGORY;
    	let AppHeaderIMAGE = '';
    	let AppHeaderRight = 'showLINK';
    	let AppHeaderRightLink = '';

        let items = this.state.PRODUCTS.map((post, i)=>{

            let outofstock = '';
            if(post.STOCK==='N')
                outofstock = ' outofstock';

            return (
                <div className="col-6" key={post.ID}>
                    <Link to={`/item/${post.ID}`} >
                        <div className="card">
                            <div className="cat-img-cover">
                                <div className={`cat-img${outofstock}`}>
                                    <img src={post.IMAGE} className="card-img-top imaged w-100" alt={post.ALT_TAG} />
                                </div>
                                {post.DISCOUNT>0 && 
                                    <div className="chip chip-info chip-offer bg-purple ml-05 mb-05">
                                        <span className="chip-label font-16">{post.DISCOUNT}%</span>
                                        <span className="font-11 text-upper">Off</span>
                                    </div>
                                }
                                {post.STOCK==='F' && 
                                    <div className="chip chip-info few-left bg-red ml-0 mb-05">
                                        <span className="chip-label font-10 text-upper">Few Left</span>
                                    </div>
                                }
                                {post.STOCK==='N' && 
                                    <div className="chip chip-info few-left bg-grey ml-0 mb-05">
                                        <span className="chip-label font-10 text-upper">Out of Stock</span>
                                    </div>
                                }
                            </div>
                            <div className="card-body pt-05 pb-0 pr-0 pl-0">
                                <p className="card-text text-grey4 font-16 ft-wt-400 mb-0 text-upper text-left">{post.NAME}</p>
                                <div className="cat-price pt-05">
                                    <p className="mb-0 final-price text-dblue font-15 ft-wt-400 text-upper pr-05"> &#8377; {post.PRICE} </p>
                                    {post.MRP>0 && post.MRP>post.PRICE && <p className="mb-0 text-dgrey orginal-price"> <small>&#8377; {post.MRP}</small> </p>}
                                    {post.IS_FAVOURITE==='N' && <ion-icon class="liked-cat unlike-filled" name="heart"></ion-icon>}
                                    {post.IS_FAVOURITE==='Y' && <ion-icon class="liked-cat like-filled" name="heart"></ion-icon>}
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            );
        });

        return (
            <div>
                {this.state.LOADING && <Loader />}
	    	    {this.state.CATEGORY!=='' && <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderIMAGE={AppHeaderIMAGE} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />}
                <div id="appCapsule">
                    <div className="category-listing login-form mt-1">
                        <div className="section-title font-size-17 mb-0 text-sdgrey pr-2 pl-2 pt-1 pb-1 text-upper text-grey4">Latest
                            <button type="button" className="btn btn-filter" data-toggle="modal" data-target="#DialogBlockButton">
                                <ion-icon name="filter-outline"></ion-icon>
                            </button>
                        </div>
                        <div className="section mt-0 mb-0 pt-0 pr-0 pl-0">
                            <div className="row mr-0 ml-0">
                                {items}
                            </div>
                            {this.state.displayLoadMore==='Y' && <p className="mr-2 ml-2">
                                <button type="button" onClick={() => this.loadMore()} className="btn mt-2 btn-red btn-block btn-ssm font-14 text-upper ft-wt-400 w-100 br-4 mb-05 goBack">Load More</button>
                            </p>}
                        </div>
                    </div>
                </div>
                <div className="modal fade dialogbox filter-popup" id="DialogBlockButton" data-backdrop="static" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Filter by</h5>
                            </div>
                            <div className="modal-body mb-05">
                                <div className="custom-control custom-checkbox mb-1">
                                    <input onClick={()=>{this.handleItemOrderDisplay(1)}} type="radio" className="custom-control-input" name="rdorder" id="OrderHighToLow" defaultValue="1" />
                                    <label className="custom-control-label" htmlFor="OrderHighToLow">High to Low</label>
                                </div>
                                <div className="custom-control custom-checkbox mb-1">
                                    <input onClick={()=>{this.handleItemOrderDisplay(2)}} type="radio" className="custom-control-input" name="rdorder" id="OrderLowToHigh" defaultValue="2" />
                                    <label className="custom-control-label" htmlFor="OrderLowToHigh">Low to High</label>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="btn-list">
                                    <button type="button" className="btn btn-text-secondary btn-block" data-dismiss="modal">CLOSE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BottomMenu LOCATION={this.state.LOCATION} />
                {this.state.CUSTOMER_DET && <SidebarPanel CUSTOMER_DET={this.state.CUSTOMER_DET}/>}
            </div>
        );
    }
}
      
export default Category;