import React from 'react';
import {Link} from 'react-router-dom';

const HomeCategory = (props) => {

    let Category = props.category.map((post, i)=>{
        return (
            <Link className="btn btn-outline-mix rounded btn-lsm mr-1 newshadowed" to={`/category/${post.ID}`} key={post.ID}>
                <img src={post.IMAGE} className="card-img-top imaged w-100" alt={post.ALT_TAG} />
                <p className="font-10 text-dgrey ft-wt-400">{post.NAME}</p>
            </Link>
        );
    });

    return (
        <div>
            <div className="section-title pt-3 pl-2 pr-2 font-16 mb-0 text-grey2 ft-wt-400 text-upper">Top Categories
                <Link to="/categories">
                    <span className="badge bt-blue font-size-10 ht-20">View All</span>
                </Link>
            </div>
            <div className="wide-block wide-block2">
                <div className="text-upper pb-2" style={{'display': 'inline-flex'}}>
                    {Category}
                </div>
            </div>
        </div>
    );
}
  
export default HomeCategory;