import React from 'react';
import {Link} from 'react-router-dom';
import Loader from './Loader';
import AppHeader from './AppHeader';
import BottomMenu from './BottomMenu';
import SidebarPanel from './SidebarPanel';

import $ from "jquery";

class Categories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            LOCATION: this.props.location.pathname,
            LOADING: true, 
            CATEGORIES: [],
            CUSTOMER_DET: '',
            FAVOURITES: 0,
            customerid: localStorage.getItem('userData'),
        };
    }

    GetCategoryList()
    {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'CATEGORY_LIST', customer: this.state.customerid})
        };

        fetch(global.config.bURL+"category_list.php", requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {

                let categories = result.CATEGORY;
                let FAVOURITES = result.FAVOURITES;
                var CUSTOMER_DET = 'Retail~~**~~Hi User';
                if(result.CUSTOMER!=='' && result.CUSTOMER!==undefined && result.CUSTOMER!=='undefined')
                    CUSTOMER_DET = result.CUSTOMER;
                    
                localStorage.setItem('nkis_favourites', FAVOURITES);
                    
                this.setState({ 
                	CATEGORIES:categories, 
                    FAVOURITES: FAVOURITES, 
                    CUSTOMER_DET: CUSTOMER_DET,
                	LOADING: false, 
                });
            });
    }

    componentDidMount() {
    	let mount = $("div").hasClass("modal-backdrop");

    	if (mount === true) {
    	    $('body').removeClass('modal-open');
    	    $(".modal-backdrop").remove();
    	    //window.location.reload(true);
    	} 

        this.GetCategoryList();
    }

    render() { 
    	let AppHeaderClass = 'nkis-header appHeader no-shadow';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = '/home';
    	let AppHeaderTitle = 'Categories';
    	let AppHeaderIMAGE = '';
    	let AppHeaderRight = 'showLINK';
    	let AppHeaderRightLink = '';
        
        let categories = this.state.CATEGORIES.map((post, i)=>{

            return (
                <li key={post.ID}>
                    <Link to={`/category/${post.ID}`} className="item">
                        <div className="imageWrapper image-cove">
                            <img src={post.IMAGE} alt={post.ALT_TAG} />
                        </div>
                        <div className="in">
                            <div className="text-left text-upper font-16"> {post.NAME}
                                <div className="text-muted font-13 pt-05">
                                    {post.PRODUCTS>0 && <span><span className="text-grey4">{post.PRODUCTS}</span> product  &nbsp;</span>}
                                    {post.BRANDS>0 && <span><span className="text-grey4">{post.BRANDS}</span> Brands  &nbsp;</span>}
                                </div>
                            </div>
                        </div>
                    </Link>
                </li>
            );
        });

        return (
            <div>
                {this.state.LOADING && <Loader />}
	    	    <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderIMAGE={AppHeaderIMAGE} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
                <div id="appCapsule">
                    <div className="category-listing login-form mt-1">
                        <div className="category-list section mt-0 mb-0 pt-0 pr-0 pl-0">
                            <ul className="listview bg-transparent image-listview media mb-2">
                                {categories}
                            </ul>
                        </div>
                    </div>  
                </div>
                <BottomMenu LOCATION={this.state.LOCATION} />
                {this.state.CUSTOMER_DET && <SidebarPanel CUSTOMER_DET={this.state.CUSTOMER_DET}/>}
            </div>
        );
    }
}
      
export default Categories;